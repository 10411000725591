import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import SideMenu from "../SideMenu";

const Layout = () => {
  return (
    <div id="layout-wrapper">
      <Header />
      <div style={{marginTop:"15px"}}></div>
      <SideMenu />
      <div className="main-content">
        <div className="page-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
