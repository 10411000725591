import React, { useState } from "react";
import "../../css/pages/dashboard.css";
import DashboardTabs from "./DashboardTabs";
import PageHeader from "../../components/PageHeader";

const Dashboard = () => {

  return (
    <div className="dashboard-container">
      <PageHeader title="Dashboard"/>
      <DashboardTabs />
    </div>
  );
};

export default Dashboard;
