import React, { useEffect } from "react";
// import "./createUser.css"
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  alpha,
  styled,
} from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Permission() {
  const { user } = useSelector((state) => state.user);

  const [userPermissionCustomers, setuserPermissionCustomers] = React.useState(
    []
  );
  const [userPermissionMenu, setuserPermissionMenu] = React.useState([]);
  const [userPermission, setuserPermission] = React.useState([]);
  const [userLocations, setuserLocations] = React.useState([]);
  const [usersList, setusersList] = React.useState([]);
  const [menuList, setmenuList] = React.useState([]);
  const navigate = useNavigate();

  const [selectedCustIds, setselectedCust] = React.useState([]);
  const [selectedlocids, setselectedlocids] = React.useState([]);

  const [userId, setUserId] = React.useState("");
  const currentUserID = user.Id;

  const [personName, setPersonName] = React.useState([]);
  const [locationName, setlocationName] = React.useState([]);

  // console.log("permission pagecurrentUserID", currentUserID)

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLocChange = (event) => {
    const {
      target: { value },
    } = event;
    setlocationName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCheckChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  const handleCheckChangeLoc = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  const apiUrlPermissionData =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/GetUserPermissionsPgData";
  const apiUrlPermissionList =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/GetMenuList";
  const apiUrlSavePermission =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/SaveUserPermissions";

  const apiUrlPermissionUser =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/GetUserPermissions";

  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);

  const [userMsg, setUserMsg] = React.useState("");

  const [msgCss, setmsgCss] = React.useState("");

  let permissionCustomers = [];
  let permissionLocations = [];

  const [selectedItems, setselectedItems] = React.useState([]);

  async function getPermissionsData(param) {
    const responseUserPerm = await getPermissions(param);
    if (responseUserPerm) {
      setuserPermission(responseUserPerm?.Payload.Table1);
      permissionCustomers = responseUserPerm?.Payload.Table1;

      setuserLocations(responseUserPerm?.Payload.Table2);
      permissionLocations = responseUserPerm?.Payload.Table2;

      setusersList(responseUserPerm?.Payload.Table);
      //  console.log("responseUserPerm", responseUserPerm)
    }
  }

  async function getPermissions(credentials) {
    return fetch(apiUrlPermissionData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function getPermissionsUser(credentials) {
    return fetch(apiUrlPermissionUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function getPermissionsByUser(param) {
    const responseUserPerm = await getPermissionsUser({
      userId: param,
    });
    if (responseUserPerm?.Payload) {
      setuserPermissionMenu(responseUserPerm?.Payload.Table1);
      setuserPermissionCustomers(responseUserPerm?.Payload.Table);

      //set customers amd menu selected

      let selectedCust = [];
      responseUserPerm?.Payload.Table?.map((cust) => {
        selectedCust.push(cust.custID);
      });
      setselectedCust(selectedCust);

      //set locations
      let selectedloc = [];
      responseUserPerm?.Payload.Table2?.map((loc) => {
        selectedloc.push(loc.whseID);
      });
      setselectedlocids(selectedloc);

      //set menu
      let selectedMenu = [];
      responseUserPerm?.Payload.Table1?.map((menu) => {
        selectedMenu.push(menu.menuID);
        setLastSelectedItem(menu.menuID);
      });

      //get cust name
      let custArray = [];
      if (permissionCustomers?.length > 0) {
        selectedCust.forEach((itemP) => {
          permissionCustomers.forEach((itemUP) => {
            if (itemP == itemUP.custID) {
              custArray.push(itemUP.customerName);
            }
          });
        });
        setPersonName(custArray);
      } else {
        selectedCust.forEach((itemP) => {
          userPermission.forEach((itemUP) => {
            if (itemP == itemUP.custID) {
              custArray.push(itemUP.customerName);
            }
          });
        });
        setPersonName(custArray);
      }

      //get locations
      let locationsArray = [];
      if (permissionLocations?.length > 0) {
        selectedloc.forEach((itemP) => {
          permissionLocations.forEach((itemUP) => {
            if (itemP == itemUP.whseID) {
              locationsArray.push(itemUP.whseName);
            }
          });
        });
        setlocationName(locationsArray);
      } else {
        selectedloc.forEach((itemP) => {
          userLocations.forEach((itemUP) => {
            if (itemP == itemUP.whseID) {
              locationsArray.push(itemUP.whseName);
            }
          });
        });
        setlocationName(locationsArray);
      }

      //set menu permissions
      setselectedItems(selectedMenu);
    }
  }

  const handleUserChange = async (event) => {
    setUserId(event.target.value);
    if (event.target.value) {
      //call permissions api to get user assigned permissions
      getPermissionsByUser(event.target.value);
    }
  };

  const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color:
      theme.palette.mode === "light"
        ? theme.palette.grey[800]
        : theme.palette.grey[200],

    [`& .${treeItemClasses.content}`]: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      [`& .${treeItemClasses.label}`]: {
        fontSize: "1.2rem",
        fontWeight: 500,
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      borderRadius: "50%",
      backgroundColor:
        theme.palette.mode === "light"
          ? alpha(theme.palette.primary.main, 0.25)
          : theme.palette.primary.dark,
      color:
        theme.palette.mode === "dark" && theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1.2),
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  useEffect(() => {
    try {
      getPermissionsData({});
      getPermissionsListData({});
      setUserId(currentUserID);
      getPermissionsByUser(currentUserID);
      // setPersonName(selectedCustIds)
    } catch (e) {
      //log error
    }
  }, []);

  const submitData = (e) => {
    e.preventDefault();

    let custArray = [];
    personName.forEach((itemP) => {
      userPermission.forEach((itemUP) => {
        if (itemP == itemUP.customerName) {
          custArray.push(itemUP.custID);
        }
      });
    });

    let locaArray = [];
    locationName.forEach((itemP) => {
      userLocations.forEach((itemUP) => {
        if (itemP == itemUP.whseName) {
          locaArray.push(itemUP.whseID);
        }
      });
    });

    const createUserResponse = updateUserData({
      userId: userId,
      custIDs: custArray.toString(),
      menuIDs: selectedItems.toString(),
      whseIDs: locaArray.toString(),
    });

    // if (createUserResponse) {
    // //  console.log("createUserResponse", createUserResponse)
    //   //  window.location.reload();
    //   //navigate('/dashboard');
    // }

    //navigate('/dashboard');
  };

  async function getPermissionsListData(param) {
    const responseUserPerm = await getPermissionsList(param);

    if (responseUserPerm) {
      setmenuList(responseUserPerm?.Payload);
    }
  }

  async function getPermissionsList(credentials) {
    return fetch(apiUrlPermissionList, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function updateUserPermissions(credentials) {
    return fetch(apiUrlSavePermission, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function updateUserData(param) {
    const responseUserCreate = await updateUserPermissions(param);

    if (responseUserCreate?.Payload) {
      if (responseUserCreate?.Payload?.Table[0].status == 0) {
        setUserMsg(responseUserCreate?.Payload.Table[0].message);
        setmsgCss("loginMsg");
      } else {
        // setcreateuserData(responseUserCreate)
        // if (responseUserCreate?.Status == 0) {
        //     //set error msg
        localStorage.removeItem("navMenu");
        setUserMsg(responseUserCreate?.Payload.Table[0].message);
        setmsgCss("loginSuccMsg");
        if (responseUserCreate?.Payload.Table[0].message != "") {
          alert("Data saved successfully, Reloading settings...");

          // console.log("responseUserCreate?.Message",responseUserCreate?.Message)
          // navigate("/dashboard");

          window.location.reload();
          const timerId = setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        }
        // }
        // else {
        //     setUserMsg(responseUserCreate.Message)
        //     setmsgCss("loginSuccMsg")
        //     getuserData({})
        // }
      }
    } else {
      setUserMsg(responseUserCreate?.Message);
      setmsgCss("loginMsg");
    }
  }

  const handleItemSelectionItems = (event, itemIds) => {
    setselectedItems(itemIds);
    // console.log("itemIds", itemIds)
  };

  const handleItemSelectionToggle = (
    event,

    itemId,
    isSelected
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };

  return (
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-lg-9 col-xl-7 mt-8">
            <div className="mb-8  text-center pageHeading">
              <h1>App Settings - User</h1>
            </div>
            <div
              className="card shadow-2-strong card-registration"
              style={{ borderRadius: 15 }}
            >
              <>
                <div className="card-body p-4 p-md-5">
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <label className="form-label" htmlFor="firstName">
                            Select User
                          </label>
                          <Select
                            size="medium"
                            className=" form-control"
                            value={userId}
                            onChange={handleUserChange}
                            defaultValue={userId}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {/* <MenuItem value="Select User">Select User </MenuItem> */}
                            {usersList.map((s) => {
                              return (
                                <MenuItem
                                  defaultChecked
                                  key={s.userId}
                                  value={s.userId}
                                >
                                  {s.UserName}{" "}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <label className="form-label" htmlFor="lastName">
                            Select Customer
                          </label>
                          <Select
                            size="medium"
                            className=" form-control"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {userPermission.map((s) => (
                              <MenuItem key={s.custID} value={s.customerName}>
                                <Checkbox
                                  onChange={handleCheckChange}
                                  checked={
                                    personName.indexOf(s.customerName) > -1
                                  }
                                />
                                <ListItemText primary={s.customerName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <label className="form-label" htmlFor="firstName">
                            Select Location
                          </label>
                          <Select
                            size="medium"
                            className=" form-control"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={locationName}
                            onChange={handleLocChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {userLocations.map((s) => (
                              <MenuItem key={s.whseID} value={s.whseName}>
                                <Checkbox
                                  onChange={handleCheckChangeLoc}
                                  checked={
                                    locationName.indexOf(s.whseName) > -1
                                  }
                                />
                                <ListItemText primary={s.whseName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </>
            </div>
          </div>
        </div>

        <div>
          <h5 className="font-bold">Permissions List</h5>
          <RichTreeView
            checkboxSelection={true}
            multiSelect={true}
            selectedItems={selectedItems}
            defaultSelectedItems={["1"]}
            defaultExpandedItems={["1", "2"]}
            sx={{
              overflowX: "visible",
              minHeight: 220,
              flexGrow: 1,
              maxWidth: 300,
            }}
            slots={{ item: StyledTreeItem }}
            items={menuList}
            onSelectedItemsChange={handleItemSelectionItems}
            onItemSelectionToggle={handleItemSelectionToggle}
          />

          <div className="mt-2">
            <Button onClick={submitData} variant="contained" color="primary">
              Submit
            </Button>
            <p className={msgCss}>{userMsg}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
