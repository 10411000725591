import React from "react";
import { Box, Grid } from "@mui/material";
import CardComponent from "../../../components/Card";
import DateWiseShipment from "./DateWiseShipment";


const ContainerInsight = () => {
  return (
    <Box className="order-insight" sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <AsnCount/>
          </Grid>
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <CardComponent>
             <DateWiseShipment/>
          </CardComponent>
        </Grid>
        
        {/* <Grid item xs={12} lg={8}>
          <CardComponent>
            <Typography>On-Time/Delay Shipment</Typography>
            <img src={graph3} />
          </CardComponent>

        </Grid> */}
        {/* <Grid item xs={12} lg={4}>
          <CardComponent>
            <Typography>Recent Activity</Typography>
            <img src={graph4} />
          </CardComponent>

        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ContainerInsight;
