import React, { useEffect, useState } from "react";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import { useSelector } from "react-redux";
import { postRequest } from "../../../services/api";
import totalOrder from "assets/total_orders.svg";
import pendingOrder from "assets/pending_orders.svg";
import shippedItems from "assets/itemsShipped.svg";
import ItemShipped from "assets/items_pieces_shipped.svg";

const OrderCount = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [orderCount, setOrderCount] = useState([]);

  const Icon = (props) => {
    return <img src={props.icon} alt={props.icon} width="30" />;
  };

  const formatOrderCount = (data) => {
    let mappedObj = {
      totalShipped: { label: "Total Orders Shipped", icon: totalOrder },
      totalPending: { label: "Total Orders Pending", icon: pendingOrder },
      totalItemsShipped: { label: "Total Items Shipped", icon: shippedItems },
      totalItemQtyShipped: {
        label: "Total Item Pieces Shipped",
        icon: ItemShipped,
      },
    };
    const formatData = Object.keys(mappedObj).map((item) => {
      let sumData;
      if (item === "totalItemsShipped") {
        sumData = data.reduce((acc, obj) => {
          acc = parseFloat(obj[item]);
          return acc;
        }, 0);
      } else {
        sumData = data.reduce((acc, obj) => {
          acc += parseFloat(obj[item]);
          return acc;
        }, 0);
      }

      return {
        label: mappedObj[item]["label"],
        value: sumData,
        icon: mappedObj[item]["icon"],
      };
    });

    setOrderCount(formatData);
  };

  const fetchOrderCount = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashCounts_orders",
      payload,
      headers
    );
    formatOrderCount(response.data.Payload);
  };

  useEffect(() => {
    fetchOrderCount();
  }, [startDate, endDate, userSetting]);

  return (
    orderCount &&
    orderCount.map((item, index) => (
      <Grid item xs={3} key={index}>
        <CardComponent>
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "#eceaf9", width: 50, height: 50 }}>
              <Icon icon={item.icon} />
            </Avatar>
            <div className="card-title-wrap">
              <Typography variant="subtitle1" gutterBottom>
                {item.label}
              </Typography>
              <Typography variant="h5" component="p" sx={{ fontWeight: 700 }}>
                {item.value}
              </Typography>
            </div>
          </Stack>
        </CardComponent>
      </Grid>
    ))
  );
};

export default OrderCount;
