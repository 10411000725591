import axios from 'axios';
import { redirect } from "react-router-dom";

export const apiEndPoint = 'https://igdstg.igdistribution.net/igdcpapi'
const apiEnd = `${apiEndPoint}/api`
const api = axios.create({
  baseURL: apiEnd,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if(JSON.parse(localStorage.getItem("userSession")) <= new Date().getTime()) {
        localStorage.removeItem("user");
        localStorage.removeItem("userSetting");
        localStorage.removeItem("userSettingData");
        localStorage.removeItem("userSession");
        localStorage.removeItem("navMenu");
        window.location.reload();
      }
      
    }
    return Promise.reject(error)
  },
)

export default api;