import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import { Link } from "react-router-dom";
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import AsnDetailPanel from "./AsnDetailPanel";

const columns = [
  {
    field: "ASN_ReceiptNo",
    headerClassName: "headingbg-color",
    headerName: "ASN Receipt No",
  },
  {
    field: "ReceiptType",
    headerClassName: "headingbg-color",
    headerName: "Receipt Type",
  },
  {
    field: "ReceiptDate",
    headerClassName: "headingbg-color",
    headerName: "Receipt Date",
  },
  {
    field: "totExpectedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Expected Qty",
    width: 200,
  },
  {
    field: "totReceivedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Received Qty",
    width: 200,
  },
  {
    field: "totDamagedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Damaged Qty",
    width: 200,
  },

  {
    field: "ReceiptStatus",
    headerClassName: "headingbg-color",
    headerName: "Receipt Status",
  },
  {
    field: "ExpectedReceiptDate",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Expected Arrival Date",
  },
  {
    field: "WarehouseReference",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Warehouse Reference",
  },
  {
    field: "DateCreated",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Date Created",
  },

  {
    field: "ExternalReceiptNo",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "External Receipt No",
  },
  {
    field: "CarrierReference",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Carrier Reference",
  },
  {
    field: "ClosedDate",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Closed Date",
  },
  {
    field: "TransportationStatus",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Transportation Status",
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    width: 150,
    headerName: "Trailer Number",
  },
];

const AsnTable = (props) => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [asnData, setAsnData] = React.useState([]);

  const rows = asnData?.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    ASN_ReceiptNo: c.ASN_ReceiptNo,
    ReceiptType: c.ReceiptType,
    ReceiptDate: c.ReceiptDate,
    totExpectedQty: c.totExpectedQty,
    totReceivedQty: c.totReceivedQty,
    totDamagedQty: c.totDamagedQty,
    ReceiptStatus: c.ReceiptStatus,
    ExpectedReceiptDate: c.ExpectedReceiptDate,
    WarehouseReference: c.WarehouseReference,
    DateCreated: c.DateCreated,
    ExternalReceiptNo: c.ExternalReceiptNo,
    CarrierReference: c.CarrierReference,
    ClosedDate: c.ClosedDate,
    TransportationStatus: c.TransportationStatus,
    TrailerNumber: c.TrailerNumber,
  }));

  const fetchAsnTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: props.fromDate? props.fromDate :startDate.format("YYYY-MM-DD"),
      toDate: props.toDate ? props.toDate : endDate.format("YYYY-MM-DD"),
      receiptStatus: "All",
      receiptType: "all",
    };
    const response = await postRequest(
      "user/getDashData_ASNReceipts",
      payload,
      headers
    );
    setAsnData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAsnTableData();
  }, [startDate, endDate, userSetting]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <AsnDetailPanel row={row} />,
    []
  );

  return (
    <>
      <div
        style={{
          height: 600,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "ReceiptDate", sort: "desc" }],
            },
          }}
          density="compact"
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
        />
      </div>
    </>
  );
};

export default AsnTable;
