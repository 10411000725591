import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import BarChart from "../../../components/BarChart";
import { sumData } from "../../../utils";
import { useSelector } from "react-redux";
import { postRequest } from "../../../services/api";

const DateWiseInventory = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const dateWiseFormat = (data) => {
    let arrayResultByDay = sumData(data, "forDay", 'dataAsOfDT', 'Qty');
    if(arrayResultByDay.length > 70) {
      arrayResultByDay = sumData(data, "forMonth", 'dataAsOfDT', 'Qty');
    }
    setChartData(arrayResultByDay);
  };

  useEffect(() => {
      dateWiseFormat(data);
  }, [data]);

  const fetchDateWiseShipmentData = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashCount_inventorytBal_dtWise",
      payload,
      headers
    );
    setData(response.data.Payload);
  };

  useEffect(() => {
    fetchDateWiseShipmentData();
  }, [startDate, endDate, userSetting]);

  return (
    <div>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography>Date Wise Inventory</Typography>
      </Stack>

      <BarChart chartName={"Date Wise Inventory Balance"} chartData={chartData} name={"Quantity"}/>
    </div>
  );
};

export default DateWiseInventory;
