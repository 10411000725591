import React from 'react'
import PageHeader from '../../components/PageHeader'
import BOLReportsTable from './BOLReportsTable'


const BOLReports = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- BOL Report details" hideCalendar={true}/>
      <BOLReportsTable />
    </div>
  )
}

export default BOLReports