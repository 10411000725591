import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const AsnDetailPanel = ({ row }) => {
  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const apiRef = useGridApiContext();
  const [open, setOpen] = React.useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([]);
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([]);

  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    { field: 'LineItemNo', headerName: 'Line Item No' },
    { field: 'ReceiptNo', headerName: 'Receipt No' },
    { field: 'Item', headerName: 'Item' },
    { field: 'Pack', headerName: 'Pack' },
    { field: 'ExpectedQty', headerName: 'Expected Qty' },
    { field: 'ReceivedQty', headerName: 'Received Qty' },
    { field: 'ReceiptStatus', headerName: 'Receipt Status' },
    { field: 'ReceiptType', headerName: 'Receipt Type' },
    { field: 'ReceivedDate', headerName: 'Received Date' },
    { field: 'UOM', headerName: 'UOM' },
    { field: 'ConditionCode', headerName: 'Condition Code' },
    { field: 'CUBE', headerName: 'CUBE' },   
    { field: 'GrossWeight', headerName: 'Gross Weight' },
    { field: 'NetWeight', headerName: 'Net Weight' },
    { field: 'QCRequired', headerName: 'QC Required' },
    { field: 'QCStatus', headerName: 'QC Status' },
  ];

  const rowItems = orderItemsData.map((c, index) => ({
    id: index,
    CUBE: c.CUBE,
    ConditionCode: c.ConditionCode,
    ExpectedQty: c.ExpectedQty,
    ReceivedQty : c.ReceivedQty,
    GrossWeight: c.GrossWeight,
    Item: c.Item,
    LineItemNo: c.LineItemNo,
    NetWeight: c.NetWeight,
    Owner: c.Owner,
    Pack: c.Pack,
    QCRequired: c.QCRequired,
    QCStatus: c.QCStatus,
    ReceiptNo: c.ReceiptNo,
    ReceiptStatus: c.ReceiptStatus,
    ReceiptType: c.ReceiptType,
    ReceivedDate: c.ReceivedDate,
    ToLocation: c.ToLocation,
    UOM: c.UOM,
    Warehouse: c.Warehouse

  }))

  const columnsItemDetails = [

    { field: 'Item', headerName: 'Item' },
    { field: 'Description', headerName: 'Description' },
    { field: 'Pack', headerName: 'Pack' },
    { field: 'GrossWeight', headerName: 'Gross Weight' },

    { field: 'NetWeight', headerName: 'Net Weight' },
    { field: 'Cube', headerName: 'Cube' },
    { field: 'Class', headerName: 'Class' },
    { field: 'ItemGroup1', headerName: 'Item Group1' },
    { field: 'PICKCODE', headerName: 'PICK CODE' },
    { field: 'CartonGroup', headerName: 'Carton Group' },

    { field: 'PUTCODE', headerName: 'PUT CODE' },
    { field: 'PutAwayLocation', headerName: 'Put Away Location' },
    { field: 'PutAwayZone', headerName: 'Put Away Zone' },
    { field: 'DateItemLastCounted', headerName: 'Date Item Last Counted' },
    { field: 'InboundQCLocation', headerName: 'Inbound QC Location' },
    { field: 'RotateBy', headerName: 'Rotate By' },

    { field: 'RFDefaultReceivingPack', headerName: 'RF Default Receiving Pack' },
    { field: 'RFDefaultReceivingUOM', headerName: 'RF Default Receiving UOM' },
    { field: 'ReturnLocation', headerName: 'Return Location' },
    { field: 'InboundQCLoc', headerName: 'Inbound QC Loc' },
    { field: 'ReceiptValidation', headerName: 'Receipt Validation' },
    { field: 'OutboundQCLoc', headerName: 'Outbound QC Loc' },
  ];

  const rowItemsDetails = orderItemsDataDetails.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    Description: c.Description,
    Pack: c.Pack,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    Cube: c.Cube,
    Class: c.Class,
    ItemGroup1: c.ItemGroup1,
    PICKCODE: c.PICKCODE,
    CartonGroup: c.CartonGroup,
    PUTCODE: c.PUTCODE,
    PutAwayLocation: c.PutAwayLocation,
    PutAwayZone: c.PutAwayZone,
    DateItemLastCounted: c.DateItemLastCounted,
    InboundQCLocation: c.InboundQCLocation,
    RotateBy: c.RotateBy,
    RFDefaultReceivingPack: c.RFDefaultReceivingPack,
    RFDefaultReceivingUOM: c.RFDefaultReceivingUOM,
    ReturnLocation: c.ReturnLocation,
    InboundQCLoc: c.InboundQCLoc,
    ReceiptValidation: c.ReceiptValidation,
    OutboundQCLoc: c.OutboundQCLoc
  }))

  const createItemsData = async (rowprops) => {
    getOrderItemsDetailsApiData(rowprops.row.Item);
    setOpen(true);
    return orderItemsDataDetails?.map((c, index) => ({
      id: index,
      Item: c.Item,
      Description: c.Description,
      Pack: c.Pack,
      GrossWeight: c.GrossWeight,
    }));
  };

  const getOrderItemsDetailsApiData = async (itemNo) => {
    setIsLoading(true)
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      "itemNo": itemNo,
    };
    const response = await postRequest("user/GetItemDetails", payload, headers);
    setorderItemsDataDetails(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsApiData();
  }, [])
  

  const getOrderItemsApiData = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      asnReceiptNo: row.ASN_ReceiptNo,
    };
    const response = await postRequest("user/GetASNreceiptItems", payload, headers);
    setorderItemsData(response.data.Payload);
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Details for ASN Receipt no: #${row.ASN_ReceiptNo}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
              
                slots={{ toolbar: GridToolbar }}
                rowSelection={true}
                onRowClick={createItemsData}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1 }}
                hideFooter
                loading={isLoading}
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>

      <Box sx={{ width: "100%", height: "200px" }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Item Properties:
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", height: 120 }}>
              <DataGridPremium
                columns={columnsItemDetails}
                rows={rowItemsDetails}
                density="compact"
                hideFooter
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default AsnDetailPanel;
