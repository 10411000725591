import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import UserTabPanel from "./UserTabPanel";
import Users from "./Users";
import Permission from "./Permission";

const UserTabs = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <div className="user-tabs-wrapper">
      <div className="user-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          variant="scrollable"
        >
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Permissions" {...a11yProps(1)} />
        </Tabs>
      </div>
      <UserTabPanel value={value} index={0}>
          <Users />
      </UserTabPanel>
      <UserTabPanel value={value} index={1}>
          <Permission />
      </UserTabPanel>
    </div>
  );
};

export default UserTabs;
