import React from "react";
import ReactEcharts from "echarts-for-react";

const DonutChart = ({ chartName, chartData }) => {
  let option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: "0px",
      left: "center",
      //type: "scroll",
    },
    series: [
      {
        name: { chartName },
        top: -15,
        bottom: 60,
        type: "pie",
        radius: ["40%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: chartData,
      },
    ],
  };
  return <ReactEcharts option={option} />;
};

export default DonutChart;
