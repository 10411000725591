import React, { useEffect, useState } from "react";
import { ORDER_COUNT } from "../../../constants";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useSelector } from "react-redux";
import { postRequest } from "../../../services/api";
import inStock from "assets/instock.svg";
import outStock from "assets/out_stock.svg";

const InventoryCount = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [orderCount, setOrderCount] = useState([]);

  const Icon = (props) => {
    return <img src={props.icon} alt={props.icon} width="30" />;
  };

  const formatOrderCount = (data) => {
    let mappedObj = {
      outOfStockItems: { label: "Out of stock items", icon: inStock },
      itemsInStock: { label: "Items in stock", icon: outStock },
    };
    const formatData = Object.keys(mappedObj).map((item) => {
      const sumData = data.reduce((acc, obj) => {
        acc += parseFloat(obj[item]);
        return acc;
      }, 0);

      return {
        label: mappedObj[item]["label"],
        value: sumData,
        icon: mappedObj[item]["icon"],
      };
    });

    setOrderCount(formatData);
  };

  const fetchCount = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      asOfDate: endDate.format("YYYY-MM-DD"),
      item: "all",
      loc: "all",
      lot: "all",
    };
    const response = await postRequest(
      "user/getDashData_inventoryBal",
      payload,
      headers
    );
    formatOrderCount(
      response?.data?.Payload ? response.data.Payload.Table : []
    );
  };

  useEffect(() => {
    fetchCount();
  }, [startDate, endDate, userSetting]);

  return (
    orderCount &&
    orderCount.map((item, index) => (
      <Grid item xs={3} key={index}>
        <CardComponent>
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "#eceaf9", width: 50, height: 50 }}>
              <Icon icon={item.icon} />
            </Avatar>
            <div className="card-title-wrap">
              <Typography variant="subtitle1" gutterBottom>
                {item.label}
              </Typography>
              <Typography variant="h5" component="p" sx={{ fontWeight: 700 }}>
                {item.value}
              </Typography>
            </div>
          </Stack>
        </CardComponent>
      </Grid>
    ))
  );
};

export default InventoryCount;
