import React from 'react'
import PageHeader from '../../components/PageHeader'
import ContainerReportTable from './ContainerReportTable'


const ContainerReport = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Container Report Details" hideCalendar={true}/>
      <ContainerReportTable />
    </div>
  )
}

export default ContainerReport