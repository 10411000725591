import { Box, Button, Stack } from "@mui/material";
import printJS from "print-js";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.mjs";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// console.log(pdfjs)
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();

const PDFViewer = (props) => {
  const [numPages, setNumPages] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);

  const previousPage = () => {
    changePage(-1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const nextPage = () => {
    changePage(1);
  };

  const printDoc = () => {
    fetch(props.fileName)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        // set the blog type to final pdf
        const file = new Blob([resp], { type: "application/pdf" });

        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        printJS(fileURL);
      });
  };

  const downLoadDoc = () => {
    fetch(props.fileName)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        // set the blog type to final pdf
        const file = new Blob([resp], { type: "application/pdf" });

        // process to auto download it
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = props.orderNo + ".pdf";
        link.click();
      });
  };

  return (
    <Box sx={{display:"flex", alignItems:"center", flexDirection:"column"}}>
      <Document
        file={props.fileName}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        {...Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => <Page pageNumber={page} />)}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {/* <p>
            Page {pageNumber} of {numPages}
          </p> */}
      <Stack spacing={1} direction="row">
        <Button
          onClick={previousPage}
          disabled={pageNumber <= 1}
          variant="contained"
          color="primary"
        >
          Previous
        </Button>
        <Button
          onClick={nextPage}
          disabled={pageNumber == numPages}
          variant="contained"
          color="primary"
        >
          {" "}
          next page
        </Button>
        <Button
          className="btn btn-primary ml-2"
          onClick={printDoc}
          variant="contained"
          color="primary"
        >
          Print
        </Button>
        <Button
          className="btn btn-primary ml-2"
          onClick={downLoadDoc}
          variant="contained"
          color="primary"
        >
          Download File
        </Button>
      </Stack>
    </Box>
  );
};

export default PDFViewer;
