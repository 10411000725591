import { Box, Card, CardContent } from "@mui/material";
import React from "react";

const CardComponent = ({ children }) => {
  return (
    <Card variant="outlined">
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardComponent;
