import React from 'react'
import PageHeader from '../../components/PageHeader'
import InventoryTable from './InventoryTransactionTable'

const InventoryTransaction = () => {

  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Inventory Transaction" />
      <InventoryTable />
    </div>
  )
}

export default InventoryTransaction