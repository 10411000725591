import { Box, Paper, Stack, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const InventoryDetailPanel = ({ row }) => {
  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const apiRef = useGridApiContext();
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([]);

  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    { field: "Item", headerClassName: "headingbg-color", headerName: "Item" },
    {
      field: "Description",
      headerClassName: "headingbg-color",
      headerName: "Description",
    },
    { field: "Pack", headerClassName: "headingbg-color", headerName: "Pack" },
    {
      field: "GrossWeight",
      headerClassName: "headingbg-color",
      headerName: "Gross Weight",
    },

    {
      field: "NetWeight",
      headerClassName: "headingbg-color",
      headerName: "Net Weight",
    },
    { field: "Cube", headerClassName: "headingbg-color", headerName: "Cube" },
    { field: "Class", headerClassName: "headingbg-color", headerName: "Class" },
    {
      field: "ItemGroup1",
      headerClassName: "headingbg-color",
      headerName: "Item Group1",
    },
    {
      field: "PICKCODE",
      headerClassName: "headingbg-color",
      headerName: "PICK CODE",
    },
    {
      field: "CartonGroup",
      headerClassName: "headingbg-color",
      headerName: "Carton Group",
    },

    {
      field: "PUTCODE",
      headerClassName: "headingbg-color",
      headerName: "PUT CODE",
    },
    {
      field: "PutAwayLocation",
      headerClassName: "headingbg-color",
      headerName: "PutAway Location",
    },
    {
      field: "PutAwayZone",
      headerClassName: "headingbg-color",
      headerName: "PutAway Zone",
    },
    {
      field: "DateItemLastCounted",
      headerClassName: "headingbg-color",
      headerName: "Date Item Last Counted",
    },
    {
      field: "InboundQCLocation",
      headerClassName: "headingbg-color",
      headerName: "Inbound QC Location",
    },
    {
      field: "RotateBy",
      headerClassName: "headingbg-color",
      headerName: "Rotate By",
    },

    {
      field: "RFDefaultReceivingPack",
      headerClassName: "headingbg-color",
      headerName: "RF Default Receiving Pack",
    },
    {
      field: "RFDefaultReceivingUOM",
      headerClassName: "headingbg-color",
      headerName: "RF Default Receiving UOM",
    },
    {
      field: "ReturnLocation",
      headerClassName: "headingbg-color",
      headerName: "Return Location",
    },
    {
      field: "InboundQCLoc",
      headerClassName: "headingbg-color",
      headerName: "Inbound QC Loc",
    },
    {
      field: "ReceiptValidation",
      headerClassName: "headingbg-color",
      headerName: "Receipt Validation",
    },
    {
      field: "OutboundQCLoc",
      headerClassName: "headingbg-color",
      headerName: "Outbound QC Loc",
    },
  ];

  const rowItems = orderItemsDataDetails?.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    Description: c.Description,
    Pack: c.Pack,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    Cube: c.Cube,
    Class: c.Class,
    ItemGroup1: c.ItemGroup1,
    PICKCODE: c.PICKCODE,
    CartonGroup: c.CartonGroup,
    PUTCODE: c.PUTCODE,
    PutAwayLocation: c.PutAwayLocation,
    PutAwayZone: c.PutAwayZone,
    DateItemLastCounted: c.DateItemLastCounted,
    InboundQCLocation: c.InboundQCLocation,
    RotateBy: c.RotateBy,
    RFDefaultReceivingPack: c.RFDefaultReceivingPack,
    RFDefaultReceivingUOM: c.RFDefaultReceivingUOM,
    ReturnLocation: c.ReturnLocation,
    InboundQCLoc: c.InboundQCLoc,
    ReceiptValidation: c.ReceiptValidation,
    OutboundQCLoc: c.OutboundQCLoc,
  }));

  const getOrderItemsDetailsApiData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };

    //const statusValue = eventData.$d;
    //const dateDaoily = statusValue.toISOString().substring(0, 10);
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      itemNo: row.ItemNo,
    };
    const response = await postRequest("user/GetItemDetails", payload, headers);
    setorderItemsDataDetails(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      "viewportInnerSizeChange",
      handleViewportInnerSizeChange
    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsDetailsApiData();
  }, []);

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width,
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Order #${row.ItemNo}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                rowSelection={true}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1 }}
                hideFooter
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default memo(InventoryDetailPanel);
