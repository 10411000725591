import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const columns = [
  { field: 'InvoiceNum', headerClassName: "headingbg-color", headerName: 'Invoice Num', width: 200 },
  { field: 'InvoiceDate',         
     headerClassName: "headingbg-color", headerName: 'Invoice Date', width: 200 },
  { field: 'InvoiceAmt', headerClassName: "headingbg-color", headerName: 'Invoice Amt', width: 200 },
  // { field: 'INV_CURR_CODE', width: 120, headerClassName: "headingbg-color", headerName: 'Invoice Currency Code' },
  { field: 'DueDate', headerClassName: "headingbg-color", headerName: 'Due Date', width: 180 },
  // { field: 'InvoiceType', headerClassName: "headingbg-color", headerName: 'Invoice Type', width: 165 },
  { field: 'InvoiceStatus', headerClassName: "headingbg-color", headerName: 'Invoice Status', width: 150 },
  { field: 'CustomerCode', headerClassName: "headingbg-color", headerName: 'Customer Code', width: 150 }
]

const InvoiceReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      invoiceNum: "all",
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_invoiceDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c) => ({
    InvoiceNum: c.InvoiceNum,
    InvoiceDate: c.InvoiceDate,
    InvoiceAmt: "$" + c.InvoiceAmt,
    INV_CURR_CODE: c.INV_CURR_CODE,
    DueDate: c.DueDate,
    BillTo: c.BillTo,
    InvoiceType: c.InvoiceType,
    InvoiceStatus: c.InvoiceStatus,
    CustomerCode: c.CustomerCode
  }));

  return (
    <div
      style={{
        height: 490,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        getRowId={(row) => row?.InvoiceNum}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={rows}
      ></DataGridPremium>
    </div>
  );
};

export default InvoiceReportTable;
