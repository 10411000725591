import api from "..";


export function getRequest(URL) {
  return api.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload, header) {
  return api.post(`/${URL}`, payload, {headers: header}).then((response) => response);
}
