import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import {
  DataGridPremium,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import InventoryDetailPanel from "./InventoryDetailPanel";

const columns = [
  {
    field: "ActivityDate",
    headerClassName: "headingbg-color",
    headerName: "Activity Date",
  },
  {
    field: "Activity",
    headerClassName: "headingbg-color",
    headerName: "Activity",
  },
  {
    field: "ItemNo",
    headerClassName: "headingbg-color",
    headerName: "Item No",
  },
  { field: "Lot", headerClassName: "headingbg-color", headerName: "Lot" },
  {
    field: "ToLocation",
    headerClassName: "headingbg-color",
    headerName: "To Location",
  },
  { field: "ToLPN", headerClassName: "headingbg-color", headerName: "To LPN" },
  {
    field: "FromLocation",
    headerClassName: "headingbg-color",
    headerName: "From Location",
  },
  {
    field: "FromLPN",
    headerClassName: "headingbg-color",
    headerName: "From LPN",
  },
  {
    field: "Quantity",
    headerClassName: "headingbg-color",
    headerName: "Quantity",
  },
  {
    field: "GrossWeight",
    headerClassName: "headingbg-color",
    headerName: "Gross Weight",
  },
  {
    field: "NetWeight",
    headerClassName: "headingbg-color",
    headerName: "Net Weight",
  },
  {
    field: "TareWeight",
    headerClassName: "headingbg-color",
    headerName: "Tare Weight",
  },
  { field: "User", headerClassName: "headingbg-color", headerName: "User" },
];

const InventoryTransactionTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, userSetting } = useSelector((state) => state.user);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const [balanceData, setBalanceData] = React.useState([]);

  const rows = balanceData.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    ActivityDate: c.ActivityDate,
    Activity: c.Activity,
    ItemNo: c.ItemNo,
    Lot: c.Lot,
    ToLocation: c.ToLocation,
    ToLPN: c.ToLPN,
    FromLocation: c.FromLocation,
    FromLPN: c.FromLPN,
    Quantity: c.Quantity,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    TareWeight: c.TareWeight,
    User: c.User,
  }));

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };

    let payload = {
      activityType: "All",
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashData_inventoryTrans",
      payload,
      headers
    );
    setBalanceData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <InventoryDetailPanel row={row}  />,
    []
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds, details) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
      );
    },
    []
  );

  return (
    <>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          columns={columns}
          rows={rows}
          density="compact"
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowIdsChange
          }
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
        />
      </div>
    </>
  );
};

export default memo(InventoryTransactionTable);
