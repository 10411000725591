import React from "react";
import { Link } from "react-router-dom";
import logoigt from "../../assets/logoigt.png";
import SideMenuContent from "./SideMenuContent";
import logowhite from "../../assets/logoigt_white_slice.png";
import "../../css/sidemenu.css";

const SideMenu = () => {
  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo">
          <span className="logo-lg">
            <img src={logoigt} alt="" height="40" />
          </span>
          <span className="logo-sm">
            <img src={logowhite} alt="" height="40" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <SideMenuContent />
      </div>
    </div>
  );
};

export default SideMenu;
