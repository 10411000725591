import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MetisMenu from "@metismenu/react";
import "simplebar-react/dist/simplebar.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "metismenujs/style";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import { postRequest } from "../../services/api";

const SideMenuContent = () => {
  const [navData, setNavData] = useState([]);

  const getNavData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    try {
      const headers = {
        Authorization: `Bearer ${userData.token}`,
      };
      const payLoad = { userId: userData.Id };
      if(JSON.parse(localStorage.getItem("navMenu"))) {
        setNavData(JSON.parse(localStorage.getItem("navMenu")));
        return
      }
      const res = await postRequest("User/GetUserNavMenus", payLoad, headers);
      localStorage.setItem('navMenu', JSON.stringify(res.data.Payload))
      setNavData(res.data.Payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNavData();
  }, []);

  const checkMenuPermission = (name) => {
    return navData && navData.some((menu) => menu.menuName.includes(name));
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100">
        <MetisMenu>
          {checkMenuPermission("Dashboard") && (
            <li>
              <NavLink to="/dashboard">
                <DashboardOutlinedIcon />
                <span>Dashboard</span>
              </NavLink>
            </li>
          )}
          {checkMenuPermission("ORDERS") && (
            <li>
              <NavLink to="/orders">
                <ShoppingCartIcon />
                <span>Orders</span>
              </NavLink>
            </li>
          )}
          {checkMenuPermission("ASN") && (
            <li>
              <NavLink to="/asn">
                <ReceiptLongOutlinedIcon />
                <span>ASN</span>
              </NavLink>
            </li>
          )}
          {checkMenuPermission("INVENTORY") && (
            <li>
              <NavLink to="/inventory">
                <InventoryIcon />
                <span>Inventory</span>
              </NavLink>
            </li>
          )}
          <li
            style={{
              display: checkMenuPermission("Reports") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <DescriptionOutlinedIcon />
              <span>Reports</span>
            </Link>
            <ul>
              <li
                style={{
                  display: checkMenuPermission("Inbound") ? "contents" : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Inbound
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("ASN Receipts")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/ansRec">ASN Receipts</NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Outbound")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Outbound
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Shipment Datewise")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportOrderDate">
                      Shipment Report Date wise
                    </NavLink>
                  </li>
                  <li
                    style={{
                      display: checkMenuPermission("Shipment Carrierwise")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportOrderCarrier">
                      Shipment Report Carrier wise
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Inventory")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Inventory
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Transactions")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/invnTrans">Inventory Transaction</NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Billing") ? "contents" : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Billing
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Invoice")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportInvoiceDetails">Invoice</NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("Container")
                    ? "contents"
                    : "none",
                }}
              >
                <Link to="/#" className="has-arrow">
                  Container
                </Link>
                <ul>
                  <li
                    style={{
                      display: checkMenuPermission("Container Details")
                        ? "contents"
                        : "none",
                    }}
                  >
                    <NavLink to="/reportContainerDetails">Container Details</NavLink>
                  </li>
                </ul>
              </li>
              <li
                style={{
                  display: checkMenuPermission("BOL") ? "contents" : "none",
                }}
              >
                <NavLink to="/reportBillOfLanding">
                  <span>BOL Details</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li
            style={{
              display: checkMenuPermission("Admin") ? "contents" : "none",
            }}
          >
            <Link to="/#" className="has-arrow">
              <AdminPanelSettingsOutlinedIcon />
              <span>Admin</span>
            </Link>
            <ul>
              <li
                style={{
                  display: checkMenuPermission("App Settings")
                    ? "contents"
                    : "none",
                }}
              >
                <NavLink to="/userTabs">App Setting</NavLink>
              </li>
            </ul>
          </li>
          <li
            style={{
              display: checkMenuPermission("eSign BOL")
                ? "contents"
                : "none",
            }}
          >
            <NavLink to="/bOLUploadFile">
            <DescriptionIcon />
              <span>e-Sign BOL</span>
            </NavLink>
          </li>
        </MetisMenu>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SideMenuContent;
