import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import graph3 from "../../../assets/graph_3.png";
import graph4 from "../../../assets/graph_4.png";
import ShipmentStatus from "./ShipmentStatus";
import DateWiseInventory from "./DateWiseInventory";
import InventoryCount from "./InventoryCount";
import DateWiseDeadCount from "./DateWiseDeadCount";

const InventoryInsight = () => {
  return (
    <Box className="order-insight" sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <InventoryCount />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <CardComponent>
             <DateWiseInventory />
          </CardComponent>

        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <CardComponent>
            <ShipmentStatus />
          </CardComponent>

        </Grid> */}
        <Grid item xs={12} lg={12}>
          <CardComponent>
            <DateWiseDeadCount />
          </CardComponent>

        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryInsight;
