export const DATE_WISE_DATA = {
  Payload: [
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-25",
      orderType: "Standard Order",
      OrderNumber: "0000000027",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-06-25",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER87",
      BOLNumber: "",
      BillTo: "DISTRIVALTO",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-25",
      orderType: "Standard Order",
      OrderNumber: "0000000024",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-06-25",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER88",
      BOLNumber: "",
      BillTo: "DISTRIVALTO",
      ShipTo: "1088 DISTRIVALTO FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000036",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090116",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "REBECCA MALDONADO",
      ConsigneeAddress:
        "613 CLAYTON MEADOWS DR NE, RIO RANCHO, NM (US), ZIP-87144-8521",
      PurchaseOrder: "658846012200242",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000041",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090117",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "SIATTA FORNAH",
      ConsigneeAddress:
        "301 N BEAUREGARD ST, APT 720, ALEXANDRIA, VA (US), ZIP-22312-2911",
      PurchaseOrder: "699735134900243",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000046",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090118",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "AARON CLAY",
      ConsigneeAddress:
        "131 EMBARCADERO W, APT 3126, OAKLAND, CA (US), ZIP-94607-3778",
      PurchaseOrder: "658850293000244",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000051",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090119",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "SUSAN GOLDBERG",
      ConsigneeAddress: "6704 ROSSFORD LN, DURHAM, NC (US), ZIP-27713-6428",
      PurchaseOrder: "699737322000245",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000056",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090120",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "TERESA SHACKLEFORD",
      ConsigneeAddress: "658 RIGHT ELLIS RD, LINN, WV (US), ZIP-26384-8118",
      PurchaseOrder: "658856555000246",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000061",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090122",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "SHANNON WILSON",
      ConsigneeAddress: "644 STAG DR, WARSAW, IN (US), ZIP-46582-6732",
      PurchaseOrder: "699744125200248",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000031",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090102",
      BOLNumber: "",
      BillTo: "WAYFAIR",
      ShipTo: "GODSON THOMAS",
      ConsigneeAddress: "733 E 84TH STREET, BROOKLYN, NY (US), ZIP-11236",
      PurchaseOrder: "CS535065838",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000037",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090127",
      BOLNumber: "12345670000000190",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "BENJAMIN DAILEY",
      ConsigneeAddress: "5044 VILLAGE DR, LAS VEGAS, NV (US), ZIP-891421808",
      PurchaseOrder: "0232894052",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000066",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-25",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090126",
      BOLNumber: "12345670000000187",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "YEVGENIY SOSNOVSKIY",
      ConsigneeAddress:
        "1210 HUNT LN, 8303, SAN ANTONIO, TX (US), ZIP-782514671",
      PurchaseOrder: "0232892356",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000071",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 9.0,
      TotalShipped: 9.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090133",
      BOLNumber: "12345670000000275",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "DENNIS TURNER",
      ConsigneeAddress:
        "101 WEST VENICE AVENUE, #5, VENICE, FL (US), ZIP-34285",
      PurchaseOrder: "AWPMGNRF5S",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000040",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-25",
      ScheduledShipDate: "2024-06-25",
      ActualShipDate: "2024-06-26",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090144",
      BOLNumber: "",
      BillTo: "Gilt Global Trading and Procurement Company",
      ShipTo: "MARY SCHULER",
      ConsigneeAddress: "7555 N LINKS WAY, FOX POINT, WI (US), ZIP-53217",
      PurchaseOrder: "1165525671",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order",
      OrderNumber: "0000000166",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-06-27",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 89",
      BOLNumber: "",
      BillTo: "DISTRIVALTO",
      ShipTo: "1088 DISTRIVALTO FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000116",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088587",
      BOLNumber: "12345670000000334",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "8XDG96MK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR60",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000087",
      TrailerNumber: "HV2501116",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000118",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 84.0,
      TotalShipped: 84.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1088610",
      BOLNumber: "12345670000000318",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "6TRI71VH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000112",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000127",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 40.0,
      TotalShipped: 40.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088593",
      BOLNumber: "12345670000000321",
      BillTo: "Amazon.com",
      ShipTo: "990218X",
      ConsigneeAddress: "10240 OLD DOWD RD, CHARLOTTE, NC (US), ZIP-28214-8082",
      PurchaseOrder: "46V542TA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR60",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000087",
      TrailerNumber: "HV2501116",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000131",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088597",
      BOLNumber: "12345670000000246",
      BillTo: "Amazon.com",
      ShipTo: "9905901",
      ConsigneeAddress: "20901 KAMERIA AVE, RIVERSIDE, CA (US), ZIP-92518-1513",
      PurchaseOrder: "4N2QX6SC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR60",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000000087",
      TrailerNumber: "HV2501116",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000048",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088574",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8026750",
      ConsigneeAddress: "2 AMES DRIVE, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "89RRZM8A",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000049",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088577",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8018154",
      ConsigneeAddress: "675 ALLEN ROAD, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "8CUQXA1Y",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000050",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088592",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9929231",
      ConsigneeAddress: "32 TRADER RD, CHO1, FISHERSVILLE, VA (US), ZIP-22939",
      PurchaseOrder: "436LVU4W",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000082",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088578",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920684",
      ConsigneeAddress:
        "3200 E SAWYER RD, STL3, REPUBLIC, MO (US), ZIP-65738-2779",
      PurchaseOrder: "8E9AXI5K",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000083",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088580",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "8GV2AA7F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000101",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088576",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "8C3R7LTS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000103",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088633",
      BOLNumber: "12345670000000448",
      BillTo: "Amazon.com",
      ShipTo: "8002983",
      ConsigneeAddress: "500 MCCARTHY DR., LEWISBERRY, PA (US), ZIP-17339",
      PurchaseOrder: "7RQ67X1Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000108",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088602",
      BOLNumber: "12345670000000435",
      BillTo: "Amazon.com",
      ShipTo: "9920463",
      ConsigneeAddress:
        "13001 HIGHWAY 70, LIT2, NORTH LITTLE ROCK, AR (US), ZIP-72117-5026",
      PurchaseOrder: "679LSX5G",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000109",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088604",
      BOLNumber: "12345670000000389",
      BillTo: "Amazon.com",
      ShipTo: "9925457",
      ConsigneeAddress: "4747 REBAR AVE NE, AKR1, CANTON, OH (US), ZIP-44705",
      PurchaseOrder: "6ENN8DKO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000112",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088595",
      BOLNumber: "12345670000000347",
      BillTo: "Amazon.com",
      ShipTo: "9918256",
      ConsigneeAddress:
        "9400 LEAVENWORTH RD, FOE1, KANSAS CITY, KS (US), ZIP-66109-3665",
      PurchaseOrder: "49SNKMCY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000113",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088609",
      BOLNumber: "12345670000000376",
      BillTo: "Amazon.com",
      ShipTo: "9918361",
      ConsigneeAddress:
        "747 COURSES LANDING RD, TEB4, PENNS GROVE, NJ (US), ZIP-08069-2957",
      PurchaseOrder: "6Q2YYT6D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000121",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088586",
      BOLNumber: "12345670000000259",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "8WLGIIFE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000126",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088589",
      BOLNumber: "12345670000000305",
      BillTo: "Amazon.com",
      ShipTo: "9905863",
      ConsigneeAddress:
        "2651 OLDMANS CREEK RD, TEB3, LOGAN TOWNSHIP, NJ (US), ZIP-08085",
      PurchaseOrder: "8ZOFG68C",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000128",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088606",
      BOLNumber: "12345670000000291",
      BillTo: "Amazon.com",
      ShipTo: "8021686",
      ConsigneeAddress: "5000 COMMERCE WAY, PETERSBURG, VA (US), ZIP-23803",
      PurchaseOrder: "6M2OMSZW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000129",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088630",
      BOLNumber: "12345670000000217",
      BillTo: "Amazon.com",
      ShipTo: "992101X",
      ConsigneeAddress:
        "780 S. DUPONT HIGHWAY, ILG1, NEW CASTLE, DE (US), ZIP-19720",
      PurchaseOrder: "7N9NA24P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000136",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088608",
      BOLNumber: "12345670000000233",
      BillTo: "Amazon.com",
      ShipTo: "7801955",
      ConsigneeAddress: "19645 WAVERLY RD, EDGERTON, KS (US), ZIP-66021",
      PurchaseOrder: "6PR7U1PA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000141",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088634",
      BOLNumber: "12345670000000288",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "7VQGJX7G",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-26",
      orderType: "Standard Order E",
      OrderNumber: "0000000053",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-06-26",
      ScheduledShipDate: "2024-06-26",
      ActualShipDate: "2024-06-27",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090156",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "KEVIN THOMPSON",
      ConsigneeAddress: "205 S TAYLOR AVE, GLENDIVE, MT (US), ZIP-59330-2438",
      PurchaseOrder: "U95XGQN3QV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000151",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-06-27",
      ScheduledShipDate: "2024-06-27",
      ActualShipDate: "2024-06-27",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090173",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "LOOP & TIE C/O SELERY FULFILLMENT",
      ConsigneeAddress:
        "720 GLADIOLA STREET, SUITE C, SALT LAKE CITY, UT (US), ZIP-84104",
      PurchaseOrder: "W8KMCKPSKM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000157",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-28",
      ScheduledShipDate: "2024-06-28",
      ActualShipDate: "2024-06-27",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090171",
      BOLNumber: "",
      BillTo: "WAYFAIR",
      ShipTo: "ELIZETH RIJOS",
      ConsigneeAddress: "43 HART STREET, WATERTOWN, CT (US), ZIP-067952722",
      PurchaseOrder: "CS535663886",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000091",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 126.0,
      TotalShipped: 126.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-27",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088581",
      BOLNumber: "12345670000000477",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "8O4N8MCN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR60",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000087",
      TrailerNumber: "HV2501116",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000076",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 438.0,
      TotalShipped: 438.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090076",
      BOLNumber: "12345670000000073",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "2LMLIYAN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR56",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000119",
      TrailerNumber: "",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000042",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090088",
      BOLNumber: "12345670000000060",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "6B2VR6SP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000057",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090083",
      BOLNumber: "12345670000000057",
      BillTo: "Amazon.com",
      ShipTo: "990803X",
      ConsigneeAddress: "7001 SKIPPER RD, SAV3, MACON, GA (US), ZIP-31216-6427",
      PurchaseOrder: "3FIRZSEP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000058",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090091",
      BOLNumber: "12345670000000103",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "7HBC6QLL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000059",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090096",
      BOLNumber: "12345670000000158",
      BillTo: "Amazon.com",
      ShipTo: "9918469",
      ConsigneeAddress:
        "635 INTERNATIONAL BLVD, SWF1, ROCK TAVERN, NY (US), ZIP-12575-5150",
      PurchaseOrder: "8RJ2NBSW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000063",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090081",
      BOLNumber: "12345670000000145",
      BillTo: "Amazon.com",
      ShipTo: "9905901",
      ConsigneeAddress: "20901 KAMERIA AVE, RIVERSIDE, CA (US), ZIP-92518-1513",
      PurchaseOrder: "2QHNSKNR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000067",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090095",
      BOLNumber: "12345670000000031",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "8DY75YVP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000072",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090089",
      BOLNumber: "12345670000000132",
      BillTo: "Amazon.com",
      ShipTo: "7802293",
      ConsigneeAddress: "1180 INNOVATION WAY, FALL RIVER, MA (US), ZIP-02722",
      PurchaseOrder: "6IGX6M8X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000073",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090094",
      BOLNumber: "12345670000000086",
      BillTo: "Amazon.com",
      ShipTo: "9914099",
      ConsigneeAddress:
        "1835 US Route 9, ALB1, Castleton, NY (US), ZIP-12033-9634",
      PurchaseOrder: "7UKFJB2P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000038",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090082",
      BOLNumber: "12345670000000220",
      BillTo: "Amazon.com",
      ShipTo: "9904697",
      ConsigneeAddress: "4550 NEXUS WAY, LAS VEGAS, NV (US), ZIP-89115",
      PurchaseOrder: "3723IBFV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000039",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090085",
      BOLNumber: "12345670000000174",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "3UXIVNAI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000047",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090090",
      BOLNumber: "12345670000000161",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "74T8JUCN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000078",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1088588",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801580",
      ConsigneeAddress: "2496 W. WALNUT AVE, RIALTO, CA (US), ZIP-923763009",
      PurchaseOrder: "8YG84MAT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000102",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1088605",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9903828",
      ConsigneeAddress: "27517 PIONEER AVE, REDLANDS, CA (US), ZIP-92374",
      PurchaseOrder: "6FFMY1RU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000117",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1088599",
      BOLNumber: "12345670000000262",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "4RJ9V2PM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000146",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-27",
      ScheduledShipDate: "2024-06-27",
      ActualShipDate: "2024-06-28",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090166",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "ANDREW PUGH",
      ConsigneeAddress: "3B WRIGHT ST, LAS VEGAS, NV (US), ZIP-891152649",
      PurchaseOrder: "0232919272",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000147",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-27",
      ScheduledShipDate: "2024-06-27",
      ActualShipDate: "2024-06-28",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090167",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "LAUREEN URIEGAS",
      ConsigneeAddress: "622 OXALIS, SAN ANTONIO, TX (US), ZIP-782604378",
      PurchaseOrder: "0232921981",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000156",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-06-27",
      ScheduledShipDate: "2024-06-27",
      ActualShipDate: "2024-06-28",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090168",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "ROBERTO LOPEZ",
      ConsigneeAddress:
        "9490 BERMUDA RD, APT 1069, LAS VEGAS, NV (US), ZIP-891233581",
      PurchaseOrder: "0232922804",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-28",
      orderType: "Standard Order E",
      OrderNumber: "0000000171",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-06-28",
      ScheduledShipDate: "2024-06-28",
      ActualShipDate: "2024-06-28",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090188",
      BOLNumber: "",
      BillTo: "Distrivalto",
      ShipTo: "DISTRIVALTO",
      ConsigneeAddress:
        "2020 PONCE DE LEON BLVD, #1004, CORAL GABLES, FL (US), ZIP-33134",
      PurchaseOrder: "SAMPLES",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-28",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000062",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 150.0,
      TotalShipped: 150.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090077",
      BOLNumber: "12345670000000044",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "2LXDNQQQ",
      tracking_PROnumber: "15122257114",
      AppointmentNumber: "",
      Door: "DOOR61",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000118",
      TrailerNumber: "2400290",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000084",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 394.0,
      TotalShipped: 394.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-06-28",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1088640",
      BOLNumber: "12345670000000523",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "8639SEIW",
      tracking_PROnumber: "2230924213",
      AppointmentNumber: "",
      Door: "DOOR57",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000117",
      TrailerNumber: "524190",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000086",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-01",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1090097",
      BOLNumber: "12345670000000028",
      BillTo: "Amazon.com",
      ShipTo: "9923896",
      ConsigneeAddress: "11500 FREEMAN ROAD, RFD2, HUNTLEY, IL (US), ZIP-60142",
      PurchaseOrder: "15LN6WMM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR59",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000108",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000148",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-01",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090229",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "DEANNA MERCHANT",
      ConsigneeAddress:
        "218 N 11TH ST, APT 16, ARKADELPHIA, AR (US), ZIP-71923-4904",
      PurchaseOrder: "658886982900263",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000158",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-01",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090232",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "AMBER NESTOR",
      ConsigneeAddress:
        "491 ROUTE 9, SUN OUTDOORS CAPE MAY 240, CAPE MAY, NJ (US), ZIP-08204-4720",
      PurchaseOrder: "699782688300266",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000176",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-01",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090235",
      BOLNumber: "",
      BillTo: "QVC, Inc.",
      ShipTo: "BREE WILKEY",
      ConsigneeAddress:
        "3208 DEWAR DR, APT 203, ROCK SPRINGS, WY (US), ZIP-82901-5596",
      PurchaseOrder: "699796353700269",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000107",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-01",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1088584",
      BOLNumber: "12345670000000392",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "8TD5W6GD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000106",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 90.0,
      TotalShipped: 90.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088579",
      BOLNumber: "12345670000000350",
      BillTo: "Amazon.com",
      ShipTo: "7801181",
      ConsigneeAddress: "250 EMERALD DRIVE, JOLIET, IL (US), ZIP-60433",
      PurchaseOrder: "8FC2SXST",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 6,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000096",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 16.0,
      TotalShipped: 16.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088575",
      BOLNumber: "12345670000000549",
      BillTo: "Amazon.com",
      ShipTo: "7802544",
      ConsigneeAddress: "6050 GATEWAY COURT, GROVEPORT, OH (US), ZIP-43125",
      PurchaseOrder: "8BBRGXMM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 5,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000081",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090079",
      BOLNumber: "12345670000000129",
      BillTo: "Amazon.com",
      ShipTo: "8026726",
      ConsigneeAddress: "24300 NANDINA AVE, MORENO VALLEY, CA (US), ZIP-92551",
      PurchaseOrder: "7LE383TH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR62",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000113",
      TrailerNumber: "V560748",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000077",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088582",
      BOLNumber: "12345670000000578",
      BillTo: "Amazon.com",
      ShipTo: "8014531",
      ConsigneeAddress: "715 AIRTECH PARKWAY, PLAINFIELD, IN (US), ZIP-46168",
      PurchaseOrder: "8OA3OPMN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000075",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088573",
      BOLNumber: "12345670000000552",
      BillTo: "Amazon.com",
      ShipTo: "9905049",
      ConsigneeAddress:
        "22 HIGHTSTOWN CRANBURY STATE ROAD, CRANBURY, NJ (US), ZIP-08512",
      PurchaseOrder: "88UGL9UR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000074",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088572",
      BOLNumber: "12345670000000594",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "85GPITLQ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000065",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088583",
      BOLNumber: "12345670000000581",
      BillTo: "Amazon.com",
      ShipTo: "9920951",
      ConsigneeAddress:
        "2303 HURRICANE LANE, HOU8, FRESNO, TX (US), ZIP-77545-1129",
      PurchaseOrder: "8OKUTI3Q",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000064",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 11,
      TotalOrdered: 322.0,
      TotalShipped: 322.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090084",
      BOLNumber: "12345670000000099",
      BillTo: "Amazon.com",
      ShipTo: "9905936",
      ConsigneeAddress: "1568 N LINDEN AVE, RIALTO, CA (US), ZIP-92376-8624",
      PurchaseOrder: "3QX8JN4B",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR62",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000113",
      TrailerNumber: "V560748",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000052",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090078",
      BOLNumber: "12345670000000116",
      BillTo: "Amazon.com",
      ShipTo: "9914161",
      ConsigneeAddress:
        "12300 BERMUDA ROAD, LAS1, HENDERSON, NV (US), ZIP-89044-8746",
      PurchaseOrder: "4262HU8O",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR62",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000113",
      TrailerNumber: "V560748",
      OrderRcvdDate: "2024-06-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000132",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088611",
      BOLNumber: "12345670000000204",
      BillTo: "Amazon.com",
      ShipTo: "9908013",
      ConsigneeAddress:
        "700 GATEWAY BLVD, CMH3, MONROE, OH (US), ZIP-45050-1848",
      PurchaseOrder: "6ZBRZDGA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 8,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000111",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-06-24",
      ScheduledShipDate: "2024-06-24",
      ActualShipDate: "2024-07-02",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1088585",
      BOLNumber: "12345670000000422",
      BillTo: "Amazon.com",
      ShipTo: "9914145",
      ConsigneeAddress:
        "1656 OLD GREENSBORO RD, GSO1, KERNERSVILLE, NC (US), ZIP-27284-6855",
      PurchaseOrder: "8WAODPYB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR44",
      Route: "1",
      Stop: 7,
      LoadNumber: "0000000120",
      TrailerNumber: "V568184",
      OrderRcvdDate: "2024-06-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000159",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090242",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "NISHA LAZARE",
      ConsigneeAddress:
        "5454 NEWCASTLE ST, APT 0216, HOUSTON, TX (US), ZIP-770812245",
      PurchaseOrder: "0232936149",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-02",
      orderType: "Standard Order",
      OrderNumber: "0000000163",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 36.0,
      TotalShipped: 36.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-03",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER91",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 DISTRIVALTO",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-02",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000172",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090238",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "MICHAEL HAGER",
      ConsigneeAddress: "412 MAGNOLIA DR, FATE, TX (US), ZIP-750876714",
      PurchaseOrder: "0232929559",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000173",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090249",
      BOLNumber: "",
      BillTo: "Gilt Global Trading and Procurement Company",
      ShipTo: "NORAH DIMANOCHIE",
      ConsigneeAddress: "5623 SOFIA PLACE, ROUND ROCK, TX (US), ZIP-78665",
      PurchaseOrder: "1165050096",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000181",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090239",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "GERALD SOLIS SUAREZ",
      ConsigneeAddress:
        "4223 I-20 FRONTAGE RD, APT J21, VICKSBURG, MS (US), ZIP-391833433",
      PurchaseOrder: "0232931309",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000182",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090240",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "ROBERT WATSON",
      ConsigneeAddress:
        "2112 N MACKENZIE DR, HARMONY PLACE, POST FALLS, ID (US), ZIP-838546031",
      PurchaseOrder: "0232931405",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000186",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090241",
      BOLNumber: "",
      BillTo: "AAFES ? The Exchange",
      ShipTo: "SARA BETH WOJTAROWICZ",
      ConsigneeAddress: "75 DAMMANN LN, SPRINGFIELD, AR (US), ZIP-721579554",
      PurchaseOrder: "0232934981",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000187",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 1.0,
      TotalShipped: 1.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090248",
      BOLNumber: "",
      BillTo: "Gilt Global Trading and Procurement Company",
      ShipTo: "KINGA BOSSE",
      ConsigneeAddress: "4317 MONTALVO CT, NAPLES, FL (US), ZIP-34109",
      PurchaseOrder: "1165037682",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-02",
      orderType: "Standard Order E",
      OrderNumber: "0000000190",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-07-02",
      ScheduledShipDate: "2024-07-02",
      ActualShipDate: "2024-07-03",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090282",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "MY MINKY OBSESSION",
      ConsigneeAddress:
        "1881 SHEYENNE LOOP, SUITE B, FARGO, ND (US), ZIP-58102",
      PurchaseOrder: "NJ4YXW2SGZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-02",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000197",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 102.0,
      TotalShipped: 102.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-05",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090254",
      BOLNumber: "12345670000000507",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "7IM3D8DF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR55",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000116",
      TrailerNumber: "483071",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000202",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-05",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090213",
      BOLNumber: "12345670000000565",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "7MMDP8JM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000114",
      TrailerNumber: "HV2200478",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000216",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 212.0,
      TotalShipped: 212.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-05",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090203",
      BOLNumber: "12345670000000682",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "2VKMRCZS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR55",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000116",
      TrailerNumber: "483071",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000221",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-05",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090209",
      BOLNumber: "12345670000000611",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "5A1OJNCH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000114",
      TrailerNumber: "HV2200478",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000226",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 66.0,
      TotalShipped: 66.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-05",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090212",
      BOLNumber: "12345670000000679",
      BillTo: "Amazon.com",
      ShipTo: "9923896",
      ConsigneeAddress: "11500 FREEMAN ROAD, RFD2, HUNTLEY, IL (US), ZIP-60142",
      PurchaseOrder: "5ZRVJ42J",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR55",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000116",
      TrailerNumber: "483071",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000201",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090199",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991627X",
      ConsigneeAddress:
        "23714 W AMOCO RD, ORD2, CHANNAHON, IL (US), ZIP-60410-8758",
      PurchaseOrder: "1T21LG2V",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000188",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090210",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916040",
      ConsigneeAddress:
        "676 C FRED JONES BLVD, TPA3, AUBURNDALE, FL (US), ZIP-33823",
      PurchaseOrder: "5LQSFVEZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000189",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090207",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "57EX7VAM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000191",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090205",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9907912",
      ConsigneeAddress:
        "50500 MOUND RD, DET2, SHELBY TOWNSHIP, MI (US), ZIP-48317-1318",
      PurchaseOrder: "2WNEMSNB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000196",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090202",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801912",
      ConsigneeAddress: "1610 VAN BUREN ROAD, EASTON, PA (US), ZIP-18045",
      PurchaseOrder: "2ALPUKND",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000152",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 66.0,
      TotalShipped: 66.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090206",
      BOLNumber: "12345670000000637",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "54HEQBRO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000115",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000150",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090198",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918744",
      ConsigneeAddress:
        "3388 S CACTUS AVE, SBD1, BLOOMINGTON, CA (US), ZIP-92316-3819",
      PurchaseOrder: "1RY9PZEM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000211",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090216",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9903828",
      ConsigneeAddress: "27517 PIONEER AVE, REDLANDS, CA (US), ZIP-92374",
      PurchaseOrder: "8WA6NAJY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000206",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090208",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "599OSZ5B",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000160",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090215",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914161",
      ConsigneeAddress:
        "12300 BERMUDA ROAD, LAS1, HENDERSON, NV (US), ZIP-89044-8746",
      PurchaseOrder: "8NHL8LZE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000185",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 72.0,
      TotalShipped: 72.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "FEDEX FREIGHT",
      CarrierName: "FEDEX FREIGHT",
      ExternalOrderNo: "1088562",
      BOLNumber: "12345670000000640",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7835317",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000121",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000231",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "FEDEX FREIGHT",
      CarrierName: "FEDEX FREIGHT",
      ExternalOrderNo: "1088563",
      BOLNumber: "12345670000000510",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7835318",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000121",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-03-20",
      orderType: "Standard Order E",
      OrderNumber: "0000000184",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 192.0,
      TotalShipped: 192.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-08",
      CarrierCode: "FEDEX FREIGHT",
      CarrierName: "FEDEX FREIGHT",
      ExternalOrderNo: "1087872",
      BOLNumber: "12345670000000695",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7784963",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000121",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-30",
      orderType: "Standard Order E",
      OrderNumber: "0000000177",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 54.0,
      TotalShipped: 54.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-10",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090194",
      BOLNumber: "12345670000000738",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "1AUZCHPS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000109",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-30",
      orderType: "Standard Order E",
      OrderNumber: "0000000149",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 54.0,
      TotalShipped: 54.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-10",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090196",
      BOLNumber: "12345670000000653",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "7BYUA6VS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000109",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-30",
      orderType: "Standard Order E",
      OrderNumber: "0000000183",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 108.0,
      TotalShipped: 108.0,
      RequestedShipDate: "2024-07-01",
      ScheduledShipDate: "2024-07-01",
      ActualShipDate: "2024-07-10",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090195",
      BOLNumber: "12345670000000709",
      BillTo: "Amazon.com",
      ShipTo: "Amazon.com CLT2",
      ConsigneeAddress: "10240 Old Dowd Rd, Charlotte, NC, ZIP-28214",
      PurchaseOrder: "2YCBSQQL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000109",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-09",
      orderType: "Standard Order",
      OrderNumber: "0000000243",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 13.0,
      TotalShipped: 13.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 95",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order",
      OrderNumber: "0000000242",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 33.0,
      TotalShipped: 33.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 94",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order",
      OrderNumber: "0000000167",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 90",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order",
      OrderNumber: "0000000168",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 92",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-09",
      orderType: "Standard Order",
      OrderNumber: "0000000169",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 28.0,
      TotalShipped: 28.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 96",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-05",
      orderType: "Standard Order",
      OrderNumber: "0000000236",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 7.0,
      TotalShipped: 7.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 93",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT ACCOUNT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-05",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-10",
      orderType: "Standard Order E",
      OrderNumber: "0000000224",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 10,
      TotalOrdered: 288.0,
      TotalShipped: 288.0,
      RequestedShipDate: "2024-07-10",
      ScheduledShipDate: "2024-07-10",
      ActualShipDate: "2024-07-22",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090450",
      BOLNumber: "",
      BillTo: "Wal-Mart Stores, Inc",
      ShipTo: "DFW5S",
      ConsigneeAddress: "2500 E BELT LINE RD, LANCASTER, TX (US), ZIP-75146",
      PurchaseOrder: "5077275GDM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-10",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000198",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-22",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090365",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991823X",
      ConsigneeAddress:
        "3315 N KELSEY ST, FAT2, VISALIA, CA (US), ZIP-93291-1882",
      PurchaseOrder: "3A8QYQRA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000203",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-22",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090363",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "801387X",
      ConsigneeAddress: "16920 W COMMERCE DRIVE, GOODYEAR, AZ (US), ZIP-85338",
      PurchaseOrder: "35LWD6ON",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000204",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 30.0,
      TotalShipped: 30.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-12",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090370",
      BOLNumber: "12345670000000712",
      BillTo: "Amazon.com",
      ShipTo: "9914161",
      ConsigneeAddress:
        "12300 BERMUDA ROAD, LAS1, HENDERSON, NV (US), ZIP-89044-8746",
      PurchaseOrder: "3JMVNZ8A",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000207",
      TrailerNumber: "HV2505139",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000199",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 30.0,
      TotalShipped: 30.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-12",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090373",
      BOLNumber: "12345670000000741",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "72514VBO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000207",
      TrailerNumber: "HV2505139",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000256",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-12",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090372",
      BOLNumber: "12345670000000666",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "3T7GTBYA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000207",
      TrailerNumber: "HV2505139",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000223",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-22",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090368",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "3C3IKUMP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-02-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000252",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 72.0,
      TotalShipped: 72.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-07",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1087541",
      BOLNumber: "12345670000001324",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7759399",
      tracking_PROnumber: "6706831165",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000407",
      TrailerNumber: "480115",
      OrderRcvdDate: "2024-07-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-03-20",
      orderType: "Standard Order E",
      OrderNumber: "0000000266",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-07",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1087870",
      BOLNumber: "12345670000001294",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7784925",
      tracking_PROnumber: "6706831165",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000407",
      TrailerNumber: "480115",
      OrderRcvdDate: "2024-07-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-03-20",
      orderType: "Standard Order E",
      OrderNumber: "0000000200",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-07",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1087873",
      BOLNumber: "12345670000001265",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7784964",
      tracking_PROnumber: "6706831165",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000407",
      TrailerNumber: "480115",
      OrderRcvdDate: "2024-07-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-11",
      orderType: "Standard Order",
      OrderNumber: "0000000261",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 13.0,
      TotalShipped: 13.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-12",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 98",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-11",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-11",
      orderType: "Standard Order",
      OrderNumber: "0000000238",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-12",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 97",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-11",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order",
      OrderNumber: "0000000239",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 7.0,
      TotalShipped: 7.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-15",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 99",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order",
      OrderNumber: "0000000262",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 20.0,
      TotalShipped: 20.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-15",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 100",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000222",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 84.0,
      TotalShipped: 84.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-15",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090366",
      BOLNumber: "12345670000000754",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "3AUB9BOG",
      tracking_PROnumber: "223-0924219",
      AppointmentNumber: "",
      Door: "DOOR59",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000110",
      TrailerNumber: "483071",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-16",
      orderType: "Standard Order",
      OrderNumber: "0000000240",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 29.0,
      TotalShipped: 29.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-17",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 101",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000259",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090508",
      BOLNumber: "12345670000000998",
      BillTo: "Amazon.com",
      ShipTo: "9923896",
      ConsigneeAddress: "11500 FREEMAN ROAD, RFD2, HUNTLEY, IL (US), ZIP-60142",
      PurchaseOrder: "7EUCS5VA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 5,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000267",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1089351",
      BOLNumber: "12345670000000972",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "53921OPC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000205",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090503",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918280",
      ConsigneeAddress:
        "650 BOULDER DR, HEA2, BREINIGSVILLE, PA (US), ZIP-18031",
      PurchaseOrder: "6DTM9DTV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000248",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1089357",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801181",
      ConsigneeAddress: "250 EMERALD DRIVE, JOLIET, IL (US), ZIP-60433",
      PurchaseOrder: "5X5YT8VL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000257",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1089352",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "53P9MKGF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-14",
      orderType: "Standard Order E",
      OrderNumber: "0000000234",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090495",
      BOLNumber: "12345670000000985",
      BillTo: "Amazon.com",
      ShipTo: "990218X",
      ConsigneeAddress: "10240 OLD DOWD RD, CHARLOTTE, NC (US), ZIP-28214-8082",
      PurchaseOrder: "4IJ1CP3U",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-14",
      orderType: "Standard Order E",
      OrderNumber: "0000000276",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 162.0,
      TotalShipped: 162.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090494",
      BOLNumber: "12345670000000943",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "4FW9ZX1Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-14",
      orderType: "Standard Order E",
      OrderNumber: "0000000178",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 54.0,
      TotalShipped: 54.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090496",
      BOLNumber: "12345670000001034",
      BillTo: "Amazon.com",
      ShipTo: "9912622",
      ConsigneeAddress:
        "1151 S GRAHAM RD, IND9, GREENWOOD, IN (US), ZIP-46143-7830",
      PurchaseOrder: "84OWODOS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000179",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 72.0,
      TotalShipped: 72.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090500",
      BOLNumber: "12345670000000956",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "5IMYZOQT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 6,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000207",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 69.0,
      TotalShipped: 69.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-23",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1089355",
      BOLNumber: "12345670000001021",
      BillTo: "Amazon.com",
      ShipTo: "990218X",
      ConsigneeAddress: "10240 OLD DOWD RD, CHARLOTTE, NC (US), ZIP-28214-8082",
      PurchaseOrder: "5PG69ZZA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 7,
      LoadNumber: "0000000214",
      TrailerNumber: "V503352",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-17",
      orderType: "Standard Order",
      OrderNumber: "0000000263",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 21.0,
      TotalShipped: 21.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-22",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 102",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-17",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-14",
      orderType: "Standard Order E",
      OrderNumber: "0000000208",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 324.0,
      TotalShipped: 324.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-25",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090493",
      BOLNumber: "12345670000001050",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "48NO2KVR",
      tracking_PROnumber: "15122257270",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000209",
      TrailerNumber: "2500012",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000271",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 220.0,
      TotalShipped: 220.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-25",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1089363",
      BOLNumber: "12345670000000969",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "6U3G6L5M",
      tracking_PROnumber: "15122257239",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000209",
      TrailerNumber: "2500012",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000192",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090497",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "4K1ZLUHY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000272",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090505",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "72D969MC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000281",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090509",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905847",
      ConsigneeAddress: "39000 AMRHEIN ROAD, LIVONIA, MI (US), ZIP-48150",
      PurchaseOrder: "7M9E7LBI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000209",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090499",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "578N995E",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000235",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090506",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905928",
      ConsigneeAddress: "13333 103RD STREET, JACKSONVILLE, FL (US), ZIP-32221",
      PurchaseOrder: "78ZAU1UE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-21",
      orderType: "Standard Order E",
      OrderNumber: "0000000247",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: "2024-07-17",
      ScheduledShipDate: "2024-07-17",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1089350",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "52H2B1IW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000282",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090498",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "53J521FA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000258",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 114.0,
      TotalShipped: 114.0,
      RequestedShipDate: "2024-07-15",
      ScheduledShipDate: "2024-07-15",
      ActualShipDate: "2024-07-23",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090504",
      BOLNumber: "12345670000000901",
      BillTo: "Amazon.com",
      ShipTo: "9923888",
      ConsigneeAddress:
        "2150 US HWY 70 BUSINESS WEST, RDU2, SMITHFIELD, NC (US), ZIP-27577",
      PurchaseOrder: "6WXRK2BV",
      tracking_PROnumber: "2231157519",
      AppointmentNumber: "",
      Door: "DOOR60",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000111",
      TrailerNumber: "527779",
      OrderRcvdDate: "2024-07-15",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000246",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-19",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090362",
      BOLNumber: "12345670000000826",
      BillTo: "Amazon.com",
      ShipTo: "9923896",
      ConsigneeAddress: "11500 FREEMAN ROAD, RFD2, HUNTLEY, IL (US), ZIP-60142",
      PurchaseOrder: "2MAFXW9F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR57",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000212",
      TrailerNumber: "V572847",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000251",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 138.0,
      TotalShipped: 138.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-19",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090367",
      BOLNumber: "12345670000000796",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "3BMAYYVM",
      tracking_PROnumber: "2230924220",
      AppointmentNumber: "",
      Door: "DOOR57",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000213",
      TrailerNumber: "521428",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000174",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 72.0,
      TotalShipped: 72.0,
      RequestedShipDate: "2024-07-08",
      ScheduledShipDate: "2024-07-08",
      ActualShipDate: "2024-07-19",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090371",
      BOLNumber: "12345670000000871",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "3OKM7QVN",
      tracking_PROnumber: "15037205398",
      AppointmentNumber: "",
      Door: "DOOR61",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000208",
      TrailerNumber: "2400290",
      OrderRcvdDate: "2024-07-08",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-02-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000193",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 2450.0,
      TotalShipped: 2450.0,
      RequestedShipDate: "2024-07-16",
      ScheduledShipDate: "2024-07-16",
      ActualShipDate: "2024-07-22",
      CarrierCode: "CUPU",
      CarrierName: "CUSTOMER PICKUP",
      ExternalOrderNo: "1087346",
      BOLNumber: "12345670000000884",
      BillTo: "Essex Technology Group, LLC",
      ShipTo: "ESSEX TECHNOLOGY GROUP LLC DBA BARGAIN HUNT S",
      ConsigneeAddress: "3815 LOGISTICS WAY, ANTIOCH, TN (US), ZIP-37013",
      PurchaseOrder: "PO-00078065",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR57",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000352",
      TrailerNumber: "5712",
      OrderRcvdDate: "2024-07-17",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000286",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090625",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "2JQ8P1GH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000291",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 57.0,
      TotalShipped: 57.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-30",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090634",
      BOLNumber: "12345670000001193",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "6U8BNYTB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000215",
      TrailerNumber: "V503804",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000296",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090631",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918280",
      ConsigneeAddress:
        "650 BOULDER DR, HEA2, BREINIGSVILLE, PA (US), ZIP-18031",
      PurchaseOrder: "5IV1MSSW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000301",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 8,
      TotalOrdered: 81.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1090635",
      BOLNumber: "12345670000001047",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "8HYQQJZP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000302",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090626",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "2OTAWHAX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000306",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 108.0,
      TotalShipped: 108.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-30",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090628",
      BOLNumber: "12345670000001151",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "4YWSE68O",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000215",
      TrailerNumber: "V503804",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000307",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090627",
      BOLNumber: "12345670000001119",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "4P2G421L",
      tracking_PROnumber: "15122257411",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000353",
      TrailerNumber: "2400144",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000311",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090633",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991627X",
      ConsigneeAddress:
        "23714 W AMOCO RD, ORD2, CHANNAHON, IL (US), ZIP-60410-8758",
      PurchaseOrder: "6PQSQ3WR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000316",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-08-02",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090630",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "5635V53V",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000292",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-22",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090676",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "WOMEN.S AUXILIARY GIFT SHOP/SAINT FRANCIS HOS",
      ConsigneeAddress: "114 WOODLAND STREET, HARTFORD, CT (US), ZIP-06105",
      PurchaseOrder: "2CNHTMEK4D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000297",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-22",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090678",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "WOMEN.S AUXILIARY GIFT SHOP/SAINT FRANCIS HOS",
      ConsigneeAddress: "114 WOODLAND STREET, HARTFORD, CT (US), ZIP-06105",
      PurchaseOrder: "U4427DMWR4",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order E",
      OrderNumber: "0000000326",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 81.0,
      TotalShipped: 81.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-30",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1090635",
      BOLNumber: "12345670000001106",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "8HYQQJZP",
      tracking_PROnumber: "2230924114",
      AppointmentNumber: "",
      Door: "DOOR57",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000216",
      TrailerNumber: "523023",
      OrderRcvdDate: "2024-07-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-05-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000336",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 600.0,
      TotalShipped: 600.0,
      RequestedShipDate: "2024-07-30",
      ScheduledShipDate: "2024-07-30",
      ActualShipDate: "2024-08-19",
      CarrierCode: "CONWAY",
      CarrierName: "XPO LOGISTICS (FORMERLY CONWAY)",
      ExternalOrderNo: "1088801",
      BOLNumber: "12345670000001500",
      BillTo: "Ross Stores, Inc.",
      ShipTo: "DD.S DISCOUNT DC",
      ConsigneeAddress: "17800 Perris Blvd, MORENO VALLEY, CA (US), ZIP-92551",
      PurchaseOrder: "80403784",
      tracking_PROnumber: "630112523",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001203",
      TrailerNumber: "454-3031",
      OrderRcvdDate: "2024-07-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-24",
      orderType: "Standard Order E",
      OrderNumber: "0000000298",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 54.0,
      TotalShipped: 54.0,
      RequestedShipDate: "2024-07-24",
      ScheduledShipDate: "2024-07-24",
      ActualShipDate: "2024-07-25",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1090706",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "LOOP & TIE C/O SELERY FULFILLMENT",
      ConsigneeAddress:
        "720 GLADIOLA STREET, SUITE C, SALT LAKE CITY, UT (US), ZIP-84104",
      PurchaseOrder: "76KXVP64BC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000351",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 20.0,
      TotalShipped: 20.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090758",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918523",
      ConsigneeAddress:
        "4930 ALLBAUGH DR, SMF6, SACRAMENTO, CA (US), ZIP-95837-9109",
      PurchaseOrder: "23F8GNEP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000356",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090759",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "2JRM42ZI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000361",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090765",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "38D2MFKO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000362",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090766",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9925384",
      ConsigneeAddress: "5125 COMMERCE DRIVE, MDT4, YORK, PA (US), ZIP-17408",
      PurchaseOrder: "3ATDI4CJ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000366",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090763",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908544",
      ConsigneeAddress:
        "4775 DEPOT CT SE, PDX7, SALEM, OR (US), ZIP-97317-8983",
      PurchaseOrder: "321S3FSP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000367",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090770",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "3JL7FY3Y",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000670",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091681",
      BOLNumber: "12345670000002082",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "4CZWHYNJ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000001547",
      TrailerNumber: "V503546",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000671",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091657",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905928",
      ConsigneeAddress: "13333 103RD STREET, JACKSONVILLE, FL (US), ZIP-32221",
      PurchaseOrder: "8ZPX8WQY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000672",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-12",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091683",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "4IUYG3OF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: " ",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000673",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091687",
      BOLNumber: "12345670000002170",
      BillTo: "Amazon.com",
      ShipTo: "9905049",
      ConsigneeAddress:
        "22 HIGHTSTOWN CRANBURY STATE ROAD, CRANBURY, NJ (US), ZIP-08512",
      PurchaseOrder: "4RCRPYRW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000001547",
      TrailerNumber: "V503546",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000674",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091692",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "4YALJJGB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000675",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091664",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920927",
      ConsigneeAddress:
        "18007 E GARLAND AVE, GEG2, SPOKANE, WA (US), ZIP-99216-6102",
      PurchaseOrder: "2LXKK7YH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000676",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091662",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "275HQC4R",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000677",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091670",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7802544",
      ConsigneeAddress: "6050 GATEWAY COURT, GROVEPORT, OH (US), ZIP-43125",
      PurchaseOrder: "3J1PXM4A",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000678",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091690",
      BOLNumber: "12345670000001988",
      BillTo: "Amazon.com",
      ShipTo: "9918248",
      ConsigneeAddress: "1475 AKRON WAY, FTW5, FORNEY, TX (US), ZIP-75126-3566",
      PurchaseOrder: "4VD32YXD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001532",
      TrailerNumber: "V507286",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000679",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091695",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920463",
      ConsigneeAddress:
        "13001 HIGHWAY 70, LIT2, NORTH LITTLE ROCK, AR (US), ZIP-72117-5026",
      PurchaseOrder: "6DTXXWFZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000680",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091704",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8025363",
      ConsigneeAddress: "1760 COUNTY LINE RD, LAKELAND, FL (US), ZIP-33811",
      PurchaseOrder: "8EG97CXG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000681",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091661",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908013",
      ConsigneeAddress:
        "700 GATEWAY BLVD, CMH3, MONROE, OH (US), ZIP-45050-1848",
      PurchaseOrder: "26TPLJNO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000682",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091674",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9904689",
      ConsigneeAddress: "808 HOG MOUNTAIN ROAD, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "3ZOKDLTF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000663",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091677",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918256",
      ConsigneeAddress:
        "9400 LEAVENWORTH RD, FOE1, KANSAS CITY, KS (US), ZIP-66109-3665",
      PurchaseOrder: "45WN8BOE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000656",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091656",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "8VPMVWKR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-04",
      orderType: "Standard Order E",
      OrderNumber: "0000000688",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-09-04",
      ScheduledShipDate: "2024-09-04",
      ActualShipDate: "2024-09-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091821",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "CHRISTOPHER REEVE",
      ConsigneeAddress:
        "4128 FORT HENRY DRIVE, SUITE D, KINGSPORT, TN (US), ZIP-37663",
      PurchaseOrder: "8DC3HKZVXV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-04",
      orderType: "Standard Order",
      OrderNumber: "0000000731",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 38.0,
      TotalShipped: 38.0,
      RequestedShipDate: "2024-09-04",
      ScheduledShipDate: "2024-09-04",
      ActualShipDate: "2024-09-06",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 128",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-03-20",
      orderType: "Standard Order E",
      OrderNumber: "0000000707",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 36.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-04",
      ScheduledShipDate: "2024-09-04",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1087875",
      BOLNumber: "",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7784967",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-18",
      orderType: "Standard Order E",
      OrderNumber: "0000000736",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-09-07",
      ScheduledShipDate: "2024-09-07",
      ActualShipDate: "2024-09-11",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1090016",
      BOLNumber: "12345670000002200",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7915344",
      tracking_PROnumber: "47366403985442",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001562",
      TrailerNumber: "X7176",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-18",
      orderType: "Standard Order E",
      OrderNumber: "0000000698",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 36.0,
      TotalShipped: 36.0,
      RequestedShipDate: "2024-09-07",
      ScheduledShipDate: "2024-09-07",
      ActualShipDate: "2024-09-11",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1090014",
      BOLNumber: "12345670000002196",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7915342",
      tracking_PROnumber: "47366403985442",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001562",
      TrailerNumber: "X7176",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-18",
      orderType: "Standard Order E",
      OrderNumber: "0000000729",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-09-07",
      ScheduledShipDate: "2024-09-07",
      ActualShipDate: "2024-09-11",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1090012",
      BOLNumber: "12345670000002138",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7915340",
      tracking_PROnumber: "47366403985442",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001562",
      TrailerNumber: "X7176",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-18",
      orderType: "Standard Order E",
      OrderNumber: "0000000741",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 48.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-07",
      ScheduledShipDate: "2024-09-07",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1090017",
      BOLNumber: "",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7915345",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-10",
      orderType: "Standard Order E",
      OrderNumber: "0000000699",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-11",
      CarrierCode: "FEDEX ECOM",
      CarrierName: "FEDEX FREIGHT (ECONOMY)",
      ExternalOrderNo: "1090435",
      BOLNumber: "12345670000002109",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7942000",
      tracking_PROnumber: "47366403985442",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001562",
      TrailerNumber: "X7176",
      OrderRcvdDate: "2024-09-04",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-06",
      orderType: "Standard Order",
      OrderNumber: "0000000747",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 35.0,
      TotalShipped: 35.0,
      RequestedShipDate: "2024-09-06",
      ScheduledShipDate: "2024-09-06",
      ActualShipDate: "2024-09-10",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 129",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-09",
      orderType: "Standard Order",
      OrderNumber: "0000000777",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 14,
      TotalOrdered: 75.0,
      TotalShipped: 75.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-11",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 130",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-11",
      orderType: "Standard Order",
      OrderNumber: "0000000781",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 80.0,
      TotalShipped: 80.0,
      RequestedShipDate: "2024-09-11",
      ScheduledShipDate: "2024-09-11",
      ActualShipDate: "2024-09-13",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 131",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-11",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-17",
      orderType: "Standard Order E",
      OrderNumber: "0000000792",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: "2024-09-23",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092085",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "ANGELA LEDTKE",
      ConsigneeAddress:
        "1 CROSSGATES MALL ROAD - D215, NONNA & OMA.S, ALBANY, NY (US), ZIP-12203",
      PurchaseOrder: "K2578ZMRZ4",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-17",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-19",
      orderType: "Standard Order",
      OrderNumber: "0000000778",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 10,
      TotalOrdered: 46.0,
      TotalShipped: 46.0,
      RequestedShipDate: "2024-09-19",
      ScheduledShipDate: "2024-09-19",
      ActualShipDate: "2024-09-20",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 133",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-19",
      orderType: "Standard Order",
      OrderNumber: "0000000783",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 48.0,
      TotalShipped: 48.0,
      RequestedShipDate: "2024-09-19",
      ScheduledShipDate: "2024-09-19",
      ActualShipDate: "2024-09-20",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 134",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-17",
      orderType: "Standard Order E",
      OrderNumber: "0000000772",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 300.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1090580",
      BOLNumber: "",
      BillTo: "Global Promotional Sourcing, LLC",
      ShipTo: "AMERISTAR CASINO COUNCIL BLUFF / PO# C1715141",
      ConsigneeAddress: "2200 RIVER ROAD, COUNCIL BLUFFS, IA (US), ZIP-51501",
      PurchaseOrder: "PS44001063B",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-20",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000847",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092173",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8020345",
      ConsigneeAddress: "225 INFINITY DR NW, CHARLESTON, TN (US), ZIP-37310",
      PurchaseOrder: "5Y61MXEH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000841",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1092163",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "801387X",
      ConsigneeAddress: "16920 W COMMERCE DRIVE, GOODYEAR, AZ (US), ZIP-85338",
      PurchaseOrder: "1LHUBOYI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000851",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092165",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920684",
      ConsigneeAddress:
        "3200 E SAWYER RD, STL3, REPUBLIC, MO (US), ZIP-65738-2779",
      PurchaseOrder: "4B5ISHMM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000843",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092186",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908013",
      ConsigneeAddress:
        "700 GATEWAY BLVD, CMH3, MONROE, OH (US), ZIP-45050-1848",
      PurchaseOrder: "8III8NOL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000852",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092167",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920978",
      ConsigneeAddress:
        "7817 GREENBRIER ROAD, HSV1, MADISON, AL (US), ZIP-35756-4403",
      PurchaseOrder: "5TDQKA1U",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000845",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092196",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8014531",
      ConsigneeAddress: "715 AIRTECH PARKWAY, PLAINFIELD, IN (US), ZIP-46168",
      PurchaseOrder: "8GYIRCAZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000826",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092154",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8025363",
      ConsigneeAddress: "1760 COUNTY LINE RD, LAKELAND, FL (US), ZIP-33811",
      PurchaseOrder: "8BKOF3ZG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000846",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092162",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8021686",
      ConsigneeAddress: "5000 COMMERCE WAY, PETERSBURG, VA (US), ZIP-23803",
      PurchaseOrder: "859EV48H",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000827",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 1,
      TotalOrdered: 288.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1092155",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "7Q2D9JHY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000830",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092193",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801912",
      ConsigneeAddress: "1610 VAN BUREN ROAD, EASTON, PA (US), ZIP-18045",
      PurchaseOrder: "7I911JSR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000856",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092164",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "8VRLL85P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000853",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092169",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914234",
      ConsigneeAddress:
        "1200 WESTPORT RD, PIT2, IMPERIAL, PA (US), ZIP-15126-1002",
      PurchaseOrder: "8LW9B3XM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000857",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092170",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905049",
      ConsigneeAddress:
        "22 HIGHTSTOWN CRANBURY STATE ROAD, CRANBURY, NJ (US), ZIP-08512",
      PurchaseOrder: "71AEGRMJ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000858",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092180",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7802544",
      ConsigneeAddress: "6050 GATEWAY COURT, GROVEPORT, OH (US), ZIP-43125",
      PurchaseOrder: "8JLA44CU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000862",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092174",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "73562VHY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000859",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092185",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914099",
      ConsigneeAddress:
        "1696 HILLCREST AVE, ALB1, S SCHODACK, NY (US), ZIP-12033-9634",
      PurchaseOrder: "7RSL6VJR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000863",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092179",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "5AKDEDZX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000861",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1092172",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "4GOSLT7F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000864",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092184",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "7IZZR7ZX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000865",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1092183",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801580",
      ConsigneeAddress: "2496 W. WALNUT AVE, RIALTO, CA (US), ZIP-923763009",
      PurchaseOrder: "8ACH3K2X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000866",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092182",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905847",
      ConsigneeAddress: "39000 AMRHEIN ROAD, LIVONIA, MI (US), ZIP-48150",
      PurchaseOrder: "8WUDGNSY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000867",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092190",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "7PN2FZ8Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000871",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 2,
      TotalOrdered: 240.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1092188",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "8VGTGFOM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000886",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 4,
      TotalOrdered: 264.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-25",
      ScheduledShipDate: "2024-09-25",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090939",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "4OYZEG1N",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000872",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092197",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920463",
      ConsigneeAddress:
        "13001 HIGHWAY 70, LIT2, NORTH LITTLE ROCK, AR (US), ZIP-72117-5026",
      PurchaseOrder: "7DRI3NVH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000873",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 5,
      TotalOrdered: 946.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-25",
      ScheduledShipDate: "2024-09-25",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090935",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "26B7A1RD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000876",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092189",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908196",
      ConsigneeAddress:
        "6500 DAVIDSON HWY, CLT3, CONCORD, NC (US), ZIP-28027-7995",
      PurchaseOrder: "861ELRFN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000881",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092191",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9912576",
      ConsigneeAddress:
        "4532 NEWCASTLE RD, SCK1, STOCKTON, CA (US), ZIP-95215-9465",
      PurchaseOrder: "1NCLWSTX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000882",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 3,
      TotalOrdered: 162.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-25",
      ScheduledShipDate: "2024-09-25",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090940",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801181",
      ConsigneeAddress: "250 EMERALD DRIVE, JOLIET, IL (US), ZIP-60433",
      PurchaseOrder: "4SZAQG7U",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000887",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 1,
      TotalOrdered: 36.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-25",
      ScheduledShipDate: "2024-09-25",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090946",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9912622",
      ConsigneeAddress:
        "1151 S GRAHAM RD, IND9, GREENWOOD, IN (US), ZIP-46143-7830",
      PurchaseOrder: "8RCNI6OT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-23",
      orderType: "Standard Order",
      OrderNumber: "0000000331",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 11.0,
      TotalShipped: 11.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-24",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 104",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-22",
      orderType: "Standard Order",
      OrderNumber: "0000000321",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-07-22",
      ScheduledShipDate: "2024-07-22",
      ActualShipDate: "2024-07-23",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 103",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-25",
      orderType: "Standard Order",
      OrderNumber: "0000000341",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 22.0,
      TotalShipped: 22.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-30",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 105",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000387",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 24.0,
      TotalShipped: 24.0,
      RequestedShipDate: "2024-07-30",
      ScheduledShipDate: "2024-07-30",
      ActualShipDate: "2024-08-06",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090885",
      BOLNumber: "12345670000001249",
      BillTo: "Amazon.com",
      ShipTo: "9925503",
      ConsigneeAddress: "1351 S ROAD 40 E, PSC2, PASCO, WA (US), ZIP-99301",
      PurchaseOrder: "6VLP4GIG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000000457",
      TrailerNumber: "HV2501926",
      OrderRcvdDate: "2024-07-30",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-17",
      orderType: "Standard Order E",
      OrderNumber: "0000000354",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 36.0,
      TotalShipped: 36.0,
      RequestedShipDate: "2024-08-02",
      ScheduledShipDate: "2024-08-02",
      ActualShipDate: "2024-08-09",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1088411",
      BOLNumber: "",
      BillTo: "Mason Companies, Inc",
      ShipTo: "MASON COMPANIES MFC",
      ConsigneeAddress:
        "2380 LAKE VIEW DRIVE, CHIPPEWA FALLS, WI (US), ZIP-54729",
      PurchaseOrder: "7823599",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-31",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-01",
      orderType: "Standard Order",
      OrderNumber: "0000000402",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 32.0,
      TotalShipped: 32.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-02",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 109",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-01",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-02",
      orderType: "Standard Order",
      OrderNumber: "0000000407",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 13.0,
      TotalShipped: 13.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-05",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 111",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-02",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-09",
      orderType: "Standard Order",
      OrderNumber: "0000000471",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 19.0,
      TotalShipped: 19.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-13",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 114",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-09",
      orderType: "Standard Order",
      OrderNumber: "0000000476",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 26.0,
      TotalShipped: 26.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-13",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 115",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000371",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090767",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9904689",
      ConsigneeAddress: "808 HOG MOUNTAIN ROAD, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "3BAL3Z3M",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000352",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090769",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8020345",
      ConsigneeAddress: "225 INFINITY DR NW, CHARLESTON, TN (US), ZIP-37310",
      PurchaseOrder: "3HGF6VKL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000368",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090772",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918469",
      ConsigneeAddress:
        "635 INTERNATIONAL BLVD, SWF1, ROCK TAVERN, NY (US), ZIP-12575-5150",
      PurchaseOrder: "4AGEP48Q",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000372",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090771",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991630X",
      ConsigneeAddress:
        "1494 S WATERMAN AVE, SBD2, SAN BERNARDINO, CA (US), ZIP-92408-2805",
      PurchaseOrder: "46ANVZRJ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000369",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090773",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918256",
      ConsigneeAddress:
        "9400 LEAVENWORTH RD, FOE1, KANSAS CITY, KS (US), ZIP-66109-3665",
      PurchaseOrder: "4BJ6KJVZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000376",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090774",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991417X",
      ConsigneeAddress:
        "2600 N NORMANDY BLVD, MCO2, DELTONA, FL (US), ZIP-32725-2610",
      PurchaseOrder: "4UCJQFWW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000373",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090777",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9902244",
      ConsigneeAddress: "1125 W REMINGTON BLVD, ROMEOVILLE, IL (US), ZIP-60446",
      PurchaseOrder: "58I5KRJO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000381",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090776",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "559TYFKN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000363",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090778",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8025363",
      ConsigneeAddress: "1760 COUNTY LINE RD, LAKELAND, FL (US), ZIP-33811",
      PurchaseOrder: "5ODCKODF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000370",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090782",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8026750",
      ConsigneeAddress: "2 AMES DRIVE, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "6LZPH43X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000374",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090783",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "6MRP7RAD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000382",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090779",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609728",
      ConsigneeAddress: "2700 CENTER DRIVE, DUPONT, WA (US), ZIP-98327-9607",
      PurchaseOrder: "611SKBQI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000386",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090785",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7802293",
      ConsigneeAddress: "1180 INNOVATION WAY, FALL RIVER, MA (US), ZIP-02722",
      PurchaseOrder: "7BJ217RZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000391",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090784",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916202",
      ConsigneeAddress:
        "1255 GATEWAY BLVD, JVL1, BELOIT, WI (US), ZIP-53511-9701",
      PurchaseOrder: "6YY1OV3Y",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000357",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090788",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8021686",
      ConsigneeAddress: "5000 COMMERCE WAY, PETERSBURG, VA (US), ZIP-23803",
      PurchaseOrder: "8F1SIQ2A",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000375",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090787",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "801387X",
      ConsigneeAddress: "16920 W COMMERCE DRIVE, GOODYEAR, AZ (US), ZIP-85338",
      PurchaseOrder: "8CV9RTQI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000364",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 216.0,
      TotalShipped: 216.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090850",
      BOLNumber: "12345670000001278",
      BillTo: "Amazon.com",
      ShipTo: "9925503",
      ConsigneeAddress: "1351 S ROAD 40 E, PSC2, PASCO, WA (US), ZIP-99301",
      PurchaseOrder: "4IFWK3FI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000457",
      TrailerNumber: "HV2501926",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000353",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 46.0,
      TotalShipped: 46.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090760",
      BOLNumber: "12345670000001236",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "2KJLTQ7O",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000457",
      TrailerNumber: "HV2501926",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000383",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 30.0,
      TotalShipped: 30.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090762",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "2ZY8X6YK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000396",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 72.0,
      TotalShipped: 72.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-06",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090761",
      BOLNumber: "12345670000001281",
      BillTo: "Amazon.com",
      ShipTo: "9914161",
      ConsigneeAddress:
        "12300 BERMUDA ROAD, LAS1, HENDERSON, NV (US), ZIP-89044-8746",
      PurchaseOrder: "2S9FDY3Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000457",
      TrailerNumber: "HV2501926",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000384",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-09",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090768",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9903828",
      ConsigneeAddress: "27517 PIONEER AVE, REDLANDS, CA (US), ZIP-92374",
      PurchaseOrder: "3FW85QXF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order E",
      OrderNumber: "0000000397",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 99.0,
      TotalShipped: 99.0,
      RequestedShipDate: "2024-07-29",
      ScheduledShipDate: "2024-07-29",
      ActualShipDate: "2024-08-02",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090786",
      BOLNumber: "12345670000001223",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "84P8MQ4U",
      tracking_PROnumber: "15122222134",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000408",
      TrailerNumber: "2500012",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order",
      OrderNumber: "0000000346",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 26.0,
      TotalShipped: 26.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-30",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 106",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-29",
      orderType: "Standard Order",
      OrderNumber: "0000000347",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 21.0,
      TotalShipped: 21.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-30",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 107",
      BOLNumber: "12345670000001180",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-29",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-30",
      orderType: "Standard Order",
      OrderNumber: "0000000401",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 56.0,
      TotalShipped: 56.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-07-31",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 108",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-07-30",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000481",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-13",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1091213",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "CANDICE WALLACE",
      ConsigneeAddress: "231 S DOUGLAS, BEAVER, OK (US), ZIP-73932",
      PurchaseOrder: "Y8V3CKKZFB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-14",
      orderType: "Standard Order",
      OrderNumber: "0000000526",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 35.0,
      TotalShipped: 35.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-15",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 118",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-14",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000531",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091296",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801580",
      ConsigneeAddress: "2496 W. WALNUT AVE, RIALTO, CA (US), ZIP-923763009",
      PurchaseOrder: "135N8KIJ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000536",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091297",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801955",
      ConsigneeAddress: "19645 WAVERLY RD, EDGERTON, KS (US), ZIP-66021",
      PurchaseOrder: "2D8G8QER",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000541",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091299",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9902244",
      ConsigneeAddress: "1125 W REMINGTON BLVD, ROMEOVILLE, IL (US), ZIP-60446",
      PurchaseOrder: "2DP3Y8LI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-06",
      orderType: "Standard Order",
      OrderNumber: "0000000403",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 45.0,
      TotalShipped: 45.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-07",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 112",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-06",
      orderType: "Standard Order",
      OrderNumber: "0000000408",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 36.0,
      TotalShipped: 36.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-07",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 113",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000412",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 10,
      TotalOrdered: 121.0,
      TotalShipped: 121.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091009",
      BOLNumber: "12345670000001483",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "7Y6O2ULM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000000517",
      TrailerNumber: "U96955",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000416",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090993",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992390X",
      ConsigneeAddress:
        "3200 FULKERTH ROAD, MCE1, TURLOCK, CA (US), ZIP-95380-9684",
      PurchaseOrder: "5PGDP7UZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000417",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091007",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920927",
      ConsigneeAddress:
        "18007 E GARLAND AVE, GEG2, SPOKANE, WA (US), ZIP-99216-6102",
      PurchaseOrder: "7TEDZ78Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000421",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090995",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918744",
      ConsigneeAddress:
        "3388 S CACTUS AVE, SBD1, BLOOMINGTON, CA (US), ZIP-92316-3819",
      PurchaseOrder: "6AEGZ77P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000422",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091015",
      BOLNumber: "12345670000001438",
      BillTo: "Amazon.com",
      ShipTo: "9912622",
      ConsigneeAddress:
        "1151 S GRAHAM RD, IND9, GREENWOOD, IN (US), ZIP-46143-7830",
      PurchaseOrder: "4JLPVJWT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR58",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001047",
      TrailerNumber: "V530177",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000426",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1090994",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "5SZGGCAD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000431",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091005",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908196",
      ConsigneeAddress:
        "6500 DAVIDSON HWY, CLT3, CONCORD, NC (US), ZIP-28027-7995",
      PurchaseOrder: "7IR1YETQ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000432",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090997",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905847",
      ConsigneeAddress: "39000 AMRHEIN ROAD, LIVONIA, MI (US), ZIP-48150",
      PurchaseOrder: "6C98LB2E",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000433",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091004",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801912",
      ConsigneeAddress: "1610 VAN BUREN ROAD, EASTON, PA (US), ZIP-18045",
      PurchaseOrder: "7EAJ1IWG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000436",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090999",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "6G9IXB8L",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000437",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091003",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "7CFRFF2R",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000438",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 185.0,
      TotalShipped: 185.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091006",
      BOLNumber: "12345670000001454",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "7NJC237D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000000517",
      TrailerNumber: "U96955",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000439",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091016",
      BOLNumber: "12345670000001571",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "8F9P7W1X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000000517",
      TrailerNumber: "U96955",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000441",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090996",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "6B6GPUEV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000446",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091014",
      BOLNumber: "12345670000001470",
      BillTo: "Amazon.com",
      ShipTo: "9920919",
      ConsigneeAddress: "9800 SMITH RD, FWA4, FORT WAYNE, IN (US), ZIP-45809",
      PurchaseOrder: "2BT2IIVD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR58",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001047",
      TrailerNumber: "V530177",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000447",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 122.0,
      TotalShipped: 122.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091011",
      BOLNumber: "12345670000001526",
      BillTo: "Amazon.com",
      ShipTo: "9914161",
      ConsigneeAddress:
        "12300 BERMUDA ROAD, LAS1, HENDERSON, NV (US), ZIP-89044-8746",
      PurchaseOrder: "85RM2TWG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000517",
      TrailerNumber: "U96955",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000451",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091010",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918469",
      ConsigneeAddress:
        "635 INTERNATIONAL BLVD, SWF1, ROCK TAVERN, NY (US), ZIP-12575-5150",
      PurchaseOrder: "7YXNSISS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000456",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091008",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918248",
      ConsigneeAddress: "1475 AKRON WAY, FTW5, FORNEY, TX (US), ZIP-75126-3566",
      PurchaseOrder: "7W15BYAU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000358",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 54.0,
      TotalShipped: 54.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-14",
      CarrierCode: "CTII",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090998",
      BOLNumber: "12345670000001425",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "6EERC7DW",
      tracking_PROnumber: "15122222225",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000000518",
      TrailerNumber: "2500012",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000365",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090992",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918280",
      ConsigneeAddress:
        "650 BOULDER DR, HEA2, BREINIGSVILLE, PA (US), ZIP-18031",
      PurchaseOrder: "5GYKFBRI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000377",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091002",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8026750",
      ConsigneeAddress: "2 AMES DRIVE, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "7AA8OIQZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000385",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091001",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "6XZ58QLZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000392",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091000",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920978",
      ConsigneeAddress:
        "7817 GREENBRIER ROAD, HSV1, MADISON, AL (US), ZIP-35756-4403",
      PurchaseOrder: "6QL3TB6R",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000398",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091012",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "8RIIPAGB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000411",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-05",
      ScheduledShipDate: "2024-08-05",
      ActualShipDate: "2024-08-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1090989",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9904689",
      ConsigneeAddress: "808 HOG MOUNTAIN ROAD, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "4G58X48V",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-06",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-04-05",
      orderType: "Standard Order E",
      OrderNumber: "0000000393",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 1920.0,
      TotalShipped: 1920.0,
      RequestedShipDate: "2024-08-13",
      ScheduledShipDate: "2024-08-13",
      ActualShipDate: "2024-08-19",
      CarrierCode: "CUPU",
      CarrierName: "CUSTOMER PICKUP",
      ExternalOrderNo: "1088202",
      BOLNumber: "12345670000001467",
      BillTo: "Essex Technology Group, LLC",
      ShipTo: "BARGAIN HUNT STORES",
      ConsigneeAddress: "3815 LOGISTICS WAY, ANTIOCH, TN (US), ZIP-37013",
      PurchaseOrder: "00081189",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR53",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001342",
      TrailerNumber: "V131",
      OrderRcvdDate: "2024-08-07",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000486",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 76.0,
      TotalShipped: 76.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-15",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091149",
      BOLNumber: "12345670000001597",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "1H8HRTJU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001202",
      TrailerNumber: "HV2202277",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000487",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091161",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "8NAHJ52I",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000488",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091164",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "8TGBM1JH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000491",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091150",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "990218X",
      ConsigneeAddress: "10240 OLD DOWD RD, CHARLOTTE, NC (US), ZIP-28214-8082",
      PurchaseOrder: "1U6SZLJV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000516",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091165",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991627X",
      ConsigneeAddress:
        "23714 W AMOCO RD, ORD2, CHANNAHON, IL (US), ZIP-60410-8758",
      PurchaseOrder: "8UOIXKHQ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000511",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 109.0,
      TotalShipped: 109.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-15",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091163",
      BOLNumber: "12345670000001630",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "8RLJZWOS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR48",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001202",
      TrailerNumber: "HV2202277",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000506",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091162",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "8PQSESTD",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000502",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 16.0,
      TotalShipped: 16.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091155",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801130",
      ConsigneeAddress: "33333 LBJ HIGHWAY DALLAS, DALLAS, TX (US), ZIP-75241",
      PurchaseOrder: "4W776AQZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000501",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091158",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "55VNVFCB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-12",
      orderType: "Standard Order E",
      OrderNumber: "0000000496",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-12",
      ScheduledShipDate: "2024-08-12",
      ActualShipDate: "2024-08-16",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091153",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905847",
      ConsigneeAddress: "39000 AMRHEIN ROAD, LIVONIA, MI (US), ZIP-48150",
      PurchaseOrder: "3VTVR3HQ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-12",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-13",
      orderType: "Standard Order",
      OrderNumber: "0000000472",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-14",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 116",
      BOLNumber: "12345670000001584",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-13",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-13",
      orderType: "Standard Order",
      OrderNumber: "0000000521",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-14",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 117",
      BOLNumber: "12345670000001496",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-13",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000546",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091301",
      BOLNumber: "12345670000001773",
      BillTo: "Amazon.com",
      ShipTo: "8025363",
      ConsigneeAddress: "1760 COUNTY LINE RD, LAKELAND, FL (US), ZIP-33811",
      PurchaseOrder: "2UNPIZRQ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000547",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091304",
      BOLNumber: "12345670000001829",
      BillTo: "Amazon.com",
      ShipTo: "9916040",
      ConsigneeAddress:
        "676 C FRED JONES BLVD, TPA3, AUBURNDALE, FL (US), ZIP-33823",
      PurchaseOrder: "3GVDUGDX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000551",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091303",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8018154",
      ConsigneeAddress: "675 ALLEN ROAD, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "37RHDYSP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000556",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091306",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "4MF7J14E",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000561",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091305",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916180",
      ConsigneeAddress:
        "8181 W ROOSEVELT ST, GYR3, PHOENIX, AZ (US), ZIP-85043-2356",
      PurchaseOrder: "4DMM4CKK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000557",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 98.0,
      TotalShipped: 98.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091308",
      BOLNumber: "12345670000001845",
      BillTo: "Amazon.com",
      ShipTo: "9908358",
      ConsigneeAddress: "3923 S B ST, SMF3, STOCKTON, CA (US), ZIP-95206-8202",
      PurchaseOrder: "53RPXZ7T",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 4,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000562",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091309",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908544",
      ConsigneeAddress:
        "4775 DEPOT CT SE, PDX7, SALEM, OR (US), ZIP-97317-8983",
      PurchaseOrder: "57H967WX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000566",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091310",
      BOLNumber: "12345670000001803",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "5FXEQOBG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 5,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000537",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091311",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905901",
      ConsigneeAddress: "20901 KAMERIA AVE, RIVERSIDE, CA (US), ZIP-92518-1513",
      PurchaseOrder: "5GFA1YQR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000558",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091312",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "5H79RMXX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000571",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091314",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "5L7K4N4E",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-20",
      orderType: "Standard Order",
      OrderNumber: "0000000591",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 28.0,
      TotalShipped: 28.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-22",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 120",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-20",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-21",
      orderType: "Standard Order",
      OrderNumber: "0000000592",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 5,
      TotalOrdered: 28.0,
      TotalShipped: 0.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: null,
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "Transfer120",
      BOLNumber: "",
      BillTo: "DISTRIVALTO",
      ShipTo: "1088 FULFILLMENT ACCOUNT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-21",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-21",
      orderType: "Standard Order",
      OrderNumber: "0000000587",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 4,
      TotalOrdered: 15.0,
      TotalShipped: 15.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-22",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 121",
      BOLNumber: "",
      BillTo: "DISTRIVALTO",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-21",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-22",
      orderType: "Standard Order",
      OrderNumber: "0000000588",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 25.0,
      TotalShipped: 25.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-26",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 122",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-22",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-23",
      orderType: "Standard Order",
      OrderNumber: "0000000596",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 37.0,
      TotalShipped: 37.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-26",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 123",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-13",
      orderType: "Standard Order E",
      OrderNumber: "0000000533",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-08-28",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091236",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "ANGELA LEDTKE / NONNA & OMA.S",
      ConsigneeAddress:
        "1 CROSSGATES MALL ROAD - D215, ALBANY, NY (US), ZIP-12203",
      PurchaseOrder: "KSVGJV28E7",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-26",
      orderType: "Standard Order E",
      OrderNumber: "0000000580",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 3,
      TotalOrdered: 22.0,
      TotalShipped: 22.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-08-28",
      CarrierCode: "FEDGRD",
      CarrierName: "FedEx Ground",
      ExternalOrderNo: "1091558",
      BOLNumber: "",
      BillTo: "FAIRE WHOLESALE, INC",
      ShipTo: "CARRIE CUTLER FARLEY",
      ConsigneeAddress: "216 HOWARD STREET, PETOSKEY, MI (US), ZIP-49770",
      PurchaseOrder: "RB8ZNP2YKB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000614",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091493",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609728",
      ConsigneeAddress: "2700 CENTER DRIVE, DUPONT, WA (US), ZIP-98327-9607",
      PurchaseOrder: "7S3YIQXB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000616",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091469",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908013",
      ConsigneeAddress:
        "700 GATEWAY BLVD, CMH3, MONROE, OH (US), ZIP-45050-1848",
      PurchaseOrder: "54A5JN8M",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000576",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091315",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920684",
      ConsigneeAddress:
        "3200 E SAWYER RD, STL3, REPUBLIC, MO (US), ZIP-65738-2779",
      PurchaseOrder: "5LNRPIUH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000613",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091486",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992101X",
      ConsigneeAddress:
        "780 S. DUPONT HIGHWAY, ILG1, NEW CASTLE, DE (US), ZIP-19720",
      PurchaseOrder: "73FJEQVI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000615",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091488",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905901",
      ConsigneeAddress: "20901 KAMERIA AVE, RIVERSIDE, CA (US), ZIP-92518-1513",
      PurchaseOrder: "7CZ4K3MI",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-27",
      orderType: "Standard Order",
      OrderNumber: "0000000593",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 14,
      TotalOrdered: 69.0,
      TotalShipped: 69.0,
      RequestedShipDate: "2024-08-27",
      ScheduledShipDate: "2024-08-27",
      ActualShipDate: "2024-08-29",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 124",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-30",
      orderType: "Standard Order",
      OrderNumber: "0000000651",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 9,
      TotalOrdered: 63.0,
      TotalShipped: 63.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-09-03",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 126",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-30",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-30",
      orderType: "Standard Order",
      OrderNumber: "0000000652",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 31.0,
      TotalShipped: 31.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-09-03",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 127",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-30",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-06-28",
      orderType: "Standard Order E",
      OrderNumber: "0000000797",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 1098.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-17",
      ScheduledShipDate: "2024-09-17",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1090191",
      BOLNumber: "",
      BillTo: "Global Promotional Sourcing, LLC",
      ShipTo: "CHOCTAW CASINO - STRINGTOWN",
      ConsigneeAddress: "893 N. HWY 69, STRINGTOWN, OK (US), ZIP-74569",
      PurchaseOrder: "P899053115A",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000757",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 7,
      TotalOrdered: 162.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091452",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166553",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000793",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 2,
      TotalOrdered: 92.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091449",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166553",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000816",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 9,
      TotalOrdered: 1078.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091451",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166550",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000542",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091316",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914099",
      ConsigneeAddress:
        "1696 HILLCREST AVE, ALB1, S SCHODACK, NY (US), ZIP-12033-9634",
      PurchaseOrder: "5VTW5FIN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000811",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 2,
      TotalOrdered: 108.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091448",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166553",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-20",
      orderType: "Standard Order E",
      OrderNumber: "0000000821",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 7,
      TotalOrdered: 162.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091453",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166553",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000822",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 7,
      TotalOrdered: 648.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091450",
      BOLNumber: "",
      BillTo: "Regal Worldwide Trading LLC",
      ShipTo: "REGAL WORLDWIDE TRADING LLC",
      ConsigneeAddress:
        "3201 SW 22ND STREET, SUITE 3255, PEMBROKE, FL (US), ZIP-33023",
      PurchaseOrder: "166549",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-24",
      orderType: "Standard Order",
      OrderNumber: "0000000896",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 26.0,
      TotalShipped: 26.0,
      RequestedShipDate: "2024-09-24",
      ScheduledShipDate: "2024-09-24",
      ActualShipDate: "2024-09-27",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 136",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-24",
      orderType: "Standard Order",
      OrderNumber: "0000000891",
      Priority: "5 - Lowest Priority",
      orderStatus: "Partial",
      TotalOrderLines: 3,
      TotalOrdered: 16.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-24",
      ScheduledShipDate: "2024-09-24",
      ActualShipDate: null,
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 135",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-17",
      orderType: "Standard Order E",
      OrderNumber: "0000000901",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 1056.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-18",
      ScheduledShipDate: "2024-09-18",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092088",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(10) SAN: SAN PEDRO DC #: 881",
      ConsigneeAddress: "401 WESTMONT DR., SAN PEDRO, CA (US), ZIP-90731",
      PurchaseOrder: "479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-24",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-16",
      orderType: "Standard Order E",
      OrderNumber: "0000000906",
      Priority: "5 - Lowest Priority",
      orderStatus: "Partial",
      TotalOrderLines: 2,
      TotalOrdered: 84.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092141",
      BOLNumber: "",
      BillTo: "BrandsMart USA",
      ShipTo: "ADC",
      ConsigneeAddress: "4000 MT ZION PARKWAY, STOCKBRIDGE, GA (US), ZIP-30281",
      PurchaseOrder: "BM722760",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-16",
      orderType: "Standard Order E",
      OrderNumber: "0000000840",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 684.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092142",
      BOLNumber: "",
      BillTo: "BrandsMart USA",
      ShipTo: "FDC FLORIDA DC",
      ConsigneeAddress:
        "3200 SW 42ND STREET, FT LAUDERDALE, FL (US), ZIP-33312",
      PurchaseOrder: "BM722759",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-25",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-17",
      orderType: "Standard Order E",
      OrderNumber: "0000000854",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-18",
      ScheduledShipDate: "2024-09-18",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092088",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(10) SAN: SAN PEDRO DC #: 881",
      ConsigneeAddress: "401 WESTMONT DR., SAN PEDRO, CA (US), ZIP-90731",
      PurchaseOrder: "10-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000543",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091319",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609728",
      ConsigneeAddress: "2700 CENTER DRIVE, DUPONT, WA (US), ZIP-98327-9607",
      PurchaseOrder: "6M63ATCR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000848",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 180.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092315",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(20) TUC: TUCSON DC #: 882",
      ConsigneeAddress: "6803 SOUTH PALO VERDE RD, TUCSON, AZ (US), ZIP-85756",
      PurchaseOrder: "20-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000860",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 168.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092316",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(40) JEF: JEFFERSON DC #: 884",
      ConsigneeAddress:
        "125 LOGISTICS CENTER PKWY, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "40-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000849",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 270.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092317",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(50) BLM: BLOOMFIELD DC #: 885",
      ConsigneeAddress: "1415 BLUE HILL AVE, BLOOMFIELD, CT (US), ZIP-06002",
      PurchaseOrder: "50-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000911",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 108.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092319",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(70) IND: BROWNSBURG DC #: 887",
      ConsigneeAddress: "850 NORTHFIELD DR, BROWNSBURG, IN (US), ZIP-46112",
      PurchaseOrder: "70-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000916",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 60.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092318",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "(60) LRD: LORDSTOWN HGDS DC",
      ConsigneeAddress:
        "2900 ELLSWORTH BAILEY RD, LORDSTOWN, OH (US), ZIP-44481",
      PurchaseOrder: "60-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000907",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 174.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1092320",
      BOLNumber: "",
      BillTo: "Marshalls Headquarters",
      ShipTo: "DC #: 890",
      ConsigneeAddress: "8201 OAK GROVE ROAD, FORT WORTH, TX (US), ZIP-76140",
      PurchaseOrder: "90-479841",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-27",
      orderType: "Standard Order",
      OrderNumber: "0000000897",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 6,
      TotalOrdered: 32.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-27",
      ScheduledShipDate: "2024-09-27",
      ActualShipDate: null,
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 137",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-26",
      orderType: "Standard Order E",
      OrderNumber: "0000000868",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 1,
      TotalOrdered: 1260.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-10-04",
      ScheduledShipDate: "2024-10-04",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091563",
      BOLNumber: "",
      BillTo: "BrandsMart USA",
      ShipTo: "FDC FLORIDA DC",
      ConsigneeAddress:
        "3200 SW 42ND STREET, FT LAUDERDALE, FL (US), ZIP-33312",
      PurchaseOrder: "BM722390",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-30",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000548",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091320",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "6UMCNOUX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-13",
      orderType: "Standard Order",
      OrderNumber: "0000000786",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-09-13",
      ScheduledShipDate: "2024-09-13",
      ActualShipDate: "2024-09-13",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 132",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-09-13",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000796",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-23",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1092031",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "7WGGRTKY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000806",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-23",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092029",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "7PD7I6LT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-16",
      orderType: "Standard Order E",
      OrderNumber: "0000000763",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 96.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-26",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1092019",
      BOLNumber: "12345670000002385",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "1ECEU93D",
      tracking_PROnumber: "842645226SAT",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001677",
      TrailerNumber: "256154",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000801",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-23",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092020",
      BOLNumber: "12345670000002327",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "3A29MZ4R",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000791",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-23",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092030",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991630X",
      ConsigneeAddress:
        "1494 S WATERMAN AVE, SBD2, SAN BERNARDINO, CA (US), ZIP-92408-2805",
      PurchaseOrder: "54J2NOOU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-15",
      orderType: "Standard Order E",
      OrderNumber: "0000000764",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 18.0,
      TotalShipped: 18.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-23",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092028",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "77ML7Q8F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-27",
      orderType: "Standard Order E",
      OrderNumber: "0000000762",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 7,
      TotalOrdered: 555.0,
      TotalShipped: 555.0,
      RequestedShipDate: "2024-09-16",
      ScheduledShipDate: "2024-09-16",
      ActualShipDate: "2024-09-24",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091580",
      BOLNumber: "12345670000002372",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "131HZO2F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001612",
      TrailerNumber: "P5220167",
      OrderRcvdDate: "2024-09-16",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000552",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 26.0,
      TotalShipped: 26.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091318",
      BOLNumber: "12345670000001832",
      BillTo: "Amazon.com",
      ShipTo: "7801130",
      ConsigneeAddress: "33333 LBJ HIGHWAY DALLAS, DALLAS, TX (US), ZIP-75241",
      PurchaseOrder: "6J8JT9TT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 3,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000538",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091321",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991630X",
      ConsigneeAddress:
        "1494 S WATERMAN AVE, SBD2, SAN BERNARDINO, CA (US), ZIP-92408-2805",
      PurchaseOrder: "6WC7PPZX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000532",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091329",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914234",
      ConsigneeAddress:
        "1200 WESTPORT RD, PIT2, IMPERIAL, PA (US), ZIP-15126-1002",
      PurchaseOrder: "8OQY2LAO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000577",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091328",
      BOLNumber: "12345670000001786",
      BillTo: "Amazon.com",
      ShipTo: "7802544",
      ConsigneeAddress: "6050 GATEWAY COURT, GROVEPORT, OH (US), ZIP-43125",
      PurchaseOrder: "8KL89HTH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 6,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000578",
      Priority: "5 - Lowest Priority",
      orderStatus: "Low Inventory",
      TotalOrderLines: 1,
      TotalOrdered: 84.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: null,
      CarrierCode: "",
      CarrierName: "",
      ExternalOrderNo: "1091327",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "816AT1VE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000581",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 117.0,
      TotalShipped: 117.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-22",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091326",
      BOLNumber: "12345670000001799",
      BillTo: "Amazon.com",
      ShipTo: "991871X",
      ConsigneeAddress:
        "6001 S AUSTIN RD, SCK4, STOCKTON, CA (US), ZIP-95215-8354",
      PurchaseOrder: "7TM3CIEO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 7,
      LoadNumber: "0000001387",
      TrailerNumber: "V511894",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order",
      OrderNumber: "0000000586",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 8,
      TotalOrdered: 45.0,
      TotalShipped: 45.0,
      RequestedShipDate: null,
      ScheduledShipDate: null,
      ActualShipDate: "2024-08-20",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 119",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000582",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091323",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "7BFD8LEU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000579",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091325",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "7S37NL4Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000559",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091322",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9904689",
      ConsigneeAddress: "808 HOG MOUNTAIN ROAD, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "799THP3C",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-19",
      orderType: "Standard Order E",
      OrderNumber: "0000000544",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-19",
      ScheduledShipDate: "2024-08-19",
      ActualShipDate: "2024-08-26",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091324",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "7HFBPS5F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-19",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000831",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092159",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918469",
      ConsigneeAddress:
        "635 INTERNATIONAL BLVD, SWF1, ROCK TAVERN, NY (US), ZIP-12575-5150",
      PurchaseOrder: "8WJLBVCV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000828",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092178",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991630X",
      ConsigneeAddress:
        "1494 S WATERMAN AVE, SBD2, SAN BERNARDINO, CA (US), ZIP-92408-2805",
      PurchaseOrder: "4ZSXWHOF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000833",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092168",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8026750",
      ConsigneeAddress: "2 AMES DRIVE, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "7C33697V",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000829",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1092177",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609728",
      ConsigneeAddress: "2700 CENTER DRIVE, DUPONT, WA (US), ZIP-98327-9607",
      PurchaseOrder: "4DRA59OH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000835",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092195",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918256",
      ConsigneeAddress:
        "9400 LEAVENWORTH RD, FOE1, KANSAS CITY, KS (US), ZIP-66109-3665",
      PurchaseOrder: "8K2HOZ3X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000832",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 1,
      TotalOrdered: 96.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1092156",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "5KJOHVGK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000836",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 2,
      TotalOrdered: 18.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092161",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "6VTV735P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000834",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1092194",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "1B6AFP1C",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000837",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1092176",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "7JRZHV7D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000617",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091476",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801955",
      ConsigneeAddress: "19645 WAVERLY RD, EDGERTON, KS (US), ZIP-66021",
      PurchaseOrder: "5S6KWZ3Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000618",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091497",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914099",
      ConsigneeAddress:
        "1696 HILLCREST AVE, ALB1, S SCHODACK, NY (US), ZIP-12033-9634",
      PurchaseOrder: "8FXQBU3J",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000621",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091470",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7802544",
      ConsigneeAddress: "6050 GATEWAY COURT, GROVEPORT, OH (US), ZIP-43125",
      PurchaseOrder: "55CWF3VV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000622",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091471",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991417X",
      ConsigneeAddress:
        "2600 N NORMANDY BLVD, MCO2, DELTONA, FL (US), ZIP-32725-2610",
      PurchaseOrder: "5A57HR9I",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000623",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091475",
      BOLNumber: "12345670000001959",
      BillTo: "Amazon.com",
      ShipTo: "8025363",
      ConsigneeAddress: "1760 COUNTY LINE RD, LAKELAND, FL (US), ZIP-33811",
      PurchaseOrder: "5RUSS7MW",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR45",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001458",
      TrailerNumber: "903928",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000624",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091494",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914234",
      ConsigneeAddress:
        "1200 WESTPORT RD, PIT2, IMPERIAL, PA (US), ZIP-15126-1002",
      PurchaseOrder: "828FDEVC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000626",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091480",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920978",
      ConsigneeAddress:
        "7817 GREENBRIER ROAD, HSV1, MADISON, AL (US), ZIP-35756-4403",
      PurchaseOrder: "67LH8NZS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000631",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091482",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8018154",
      ConsigneeAddress: "675 ALLEN ROAD, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "6A88KG2N",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000632",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091483",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920927",
      ConsigneeAddress:
        "18007 E GARLAND AVE, GEG2, SPOKANE, WA (US), ZIP-99216-6102",
      PurchaseOrder: "6D5R1ZKL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000633",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091487",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905928",
      ConsigneeAddress: "13333 103RD STREET, JACKSONVILLE, FL (US), ZIP-32221",
      PurchaseOrder: "787THF9V",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000634",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091498",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908196",
      ConsigneeAddress:
        "6500 DAVIDSON HWY, CLT3, CONCORD, NC (US), ZIP-28027-7995",
      PurchaseOrder: "8IPTCBBH",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000635",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091502",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "8UALJU7W",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000636",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091484",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8021686",
      ConsigneeAddress: "5000 COMMERCE WAY, PETERSBURG, VA (US), ZIP-23803",
      PurchaseOrder: "6KJSGFZT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000637",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091492",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801580",
      ConsigneeAddress: "2496 W. WALNUT AVE, RIALTO, CA (US), ZIP-923763009",
      PurchaseOrder: "7R17NBAS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000638",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091500",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916040",
      ConsigneeAddress:
        "676 C FRED JONES BLVD, TPA3, AUBURNDALE, FL (US), ZIP-33823",
      PurchaseOrder: "8PT3LYAM",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000641",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091499",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "8NYBZUFX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000646",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091501",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9904689",
      ConsigneeAddress: "808 HOG MOUNTAIN ROAD, JEFFERSON, GA (US), ZIP-30549",
      PurchaseOrder: "8T7TOEJN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000583",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091481",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "695GOZEE",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000584",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091489",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "992048X",
      ConsigneeAddress:
        "8991 S PORTLAND AVENUE, OKC2, OKLAHOMA CITY, OK (US), ZIP-73159-0003",
      PurchaseOrder: "7ETV67HX",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000601",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 430.0,
      TotalShipped: 430.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-08-30",
      CarrierCode: "CENTRAL",
      CarrierName: "CENTRAL TRANSPORT",
      ExternalOrderNo: "1090938",
      BOLNumber: "12345670000001933",
      BillTo: "Amazon.com",
      ShipTo: "9918841",
      ConsigneeAddress:
        "5045 PORTSMOUTH BLVD, ORF2, CHESAPEAKE, VA (US), ZIP-23321",
      PurchaseOrder: "4LLKO84D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR51",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001477",
      TrailerNumber: "2500103",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000602",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091459",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918507",
      ConsigneeAddress:
        "7130 N BROADWAY AVE, ICT2, PARK CITY, KS (US), ZIP-67219-1410",
      PurchaseOrder: "8XZ4R2WA",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000603",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091474",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801971",
      ConsigneeAddress:
        "3050 GATEWAY COMMERCE CTR DR S, EDWARDSVILLE, IL (US), ZIP-620252815",
      PurchaseOrder: "5EWHKFMV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000604",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091473",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9902244",
      ConsigneeAddress: "1125 W REMINGTON BLVD, ROMEOVILLE, IL (US), ZIP-60446",
      PurchaseOrder: "5EG9YJVS",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000605",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091490",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920463",
      ConsigneeAddress:
        "13001 HIGHWAY 70, LIT2, NORTH LITTLE ROCK, AR (US), ZIP-72117-5026",
      PurchaseOrder: "7KOX4BIT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000606",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091460",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905847",
      ConsigneeAddress: "39000 AMRHEIN ROAD, LIVONIA, MI (US), ZIP-48150",
      PurchaseOrder: "11CRML7M",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000607",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 10.0,
      TotalShipped: 10.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-08-30",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091467",
      BOLNumber: "12345670000001874",
      BillTo: "Amazon.com",
      ShipTo: "8014531",
      ConsigneeAddress: "715 AIRTECH PARKWAY, PLAINFIELD, IN (US), ZIP-46168",
      PurchaseOrder: "1Y78VOVT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000611",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091466",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "1TEXT1IG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000612",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091485",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "6LMKBVNC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000534",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091472",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918256",
      ConsigneeAddress:
        "9400 LEAVENWORTH RD, FOE1, KANSAS CITY, KS (US), ZIP-66109-3665",
      PurchaseOrder: "5AW78FGO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000535",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091491",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "7OEGBJ8X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000539",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091465",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905049",
      ConsigneeAddress:
        "22 HIGHTSTOWN CRANBURY STATE ROAD, CRANBURY, NJ (US), ZIP-08512",
      PurchaseOrder: "1JUDNORG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000540",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 6,
      TotalOrdered: 73.0,
      TotalShipped: 73.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-05",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1091508",
      BOLNumber: "12345670000001962",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "15D2YLDT",
      tracking_PROnumber: "842642802SAT",
      AppointmentNumber: "",
      Door: "DOOR50",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001478",
      TrailerNumber: "930412",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000549",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091468",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920544",
      ConsigneeAddress:
        "10384 W US HIGHWAY 90, SAT4, SAN ANTONIO, TX (US), ZIP-78245-9512",
      PurchaseOrder: "2S8AAVXU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-26",
      orderType: "Standard Order E",
      OrderNumber: "0000000550",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-05",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1091504",
      BOLNumber: "12345670000001890",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "7RM9BS7H",
      tracking_PROnumber: "842642813SAT",
      AppointmentNumber: "",
      Door: "DOOR50",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001478",
      TrailerNumber: "930412",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000560",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091461",
      BOLNumber: "12345670000002024",
      BillTo: "Amazon.com",
      ShipTo: "991613X",
      ConsigneeAddress:
        "6001 BETHLEHEM BLVD, DCA6, BALTIMORE, MD (US), ZIP-21219-104",
      PurchaseOrder: "137J8P2B",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR49",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001532",
      TrailerNumber: "V507286",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000563",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091464",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8026750",
      ConsigneeAddress: "2 AMES DRIVE, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "1H8MBWPL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000564",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091495",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916202",
      ConsigneeAddress:
        "1255 GATEWAY BLVD, JVL1, BELOIT, WI (US), ZIP-53511-9701",
      PurchaseOrder: "88JOWENB",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000572",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091462",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918523",
      ConsigneeAddress:
        "4930 ALLBAUGH DR, SMF6, SACRAMENTO, CA (US), ZIP-95837-9109",
      PurchaseOrder: "15TAKH4W",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000573",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091496",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916245",
      ConsigneeAddress:
        "11505 PROGRESS WAY, MEM6, OLIVE BRANCH, MS (US), ZIP-38654-2510",
      PurchaseOrder: "8DS7KXRR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-25",
      orderType: "Standard Order E",
      OrderNumber: "0000000574",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 30.0,
      TotalShipped: 30.0,
      RequestedShipDate: "2024-08-26",
      ScheduledShipDate: "2024-08-26",
      ActualShipDate: "2024-09-03",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091509",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "5X9N5FWP",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-08-26",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-08-27",
      orderType: "Standard Order",
      OrderNumber: "0000000589",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 5,
      TotalOrdered: 30.0,
      TotalShipped: 30.0,
      RequestedShipDate: "2024-08-27",
      ScheduledShipDate: "2024-08-27",
      ActualShipDate: "2024-08-29",
      CarrierCode: "IGD TRANSFER",
      CarrierName: "IGD TRANSFER",
      ExternalOrderNo: "TRANSFER 125",
      BOLNumber: "",
      BillTo: "",
      ShipTo: "1088 FULFILLMENT",
      ConsigneeAddress: "1950 S STATE ST, TACOMA, WA, ZIP-98405",
      PurchaseOrder: "",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: "",
      OrderRcvdDate: "2024-08-27",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000751",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091870",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9923578",
      ConsigneeAddress:
        "3875 GUTHRIE HWY, BNA6, CLARKSVILLE, TN (US), ZIP-37040",
      PurchaseOrder: "1DMVFQAN",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000756",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091873",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "4A47CW5S",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000761",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-13",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091872",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9921923",
      ConsigneeAddress:
        "8590 NE 24TH AVENUE, AMA1, AMARILLO, TX (US), ZIP-79108",
      PurchaseOrder: "45XGJSOL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000766",
      Priority: "5 - Lowest Priority",
      orderStatus: "Picking",
      TotalOrderLines: 3,
      TotalOrdered: 120.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: null,
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1091874",
      BOLNumber: "12345670000002213",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "4Q5DPWTU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: " ",
      Route: " ",
      Stop: null,
      LoadNumber: " ",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-08",
      orderType: "Standard Order E",
      OrderNumber: "0000000771",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 90.0,
      TotalShipped: 90.0,
      RequestedShipDate: "2024-09-09",
      ScheduledShipDate: "2024-09-09",
      ActualShipDate: "2024-09-17",
      CarrierCode: "EXLA",
      CarrierName: "ESTES EXPRESS",
      ExternalOrderNo: "1091875",
      BOLNumber: "12345670000002226",
      BillTo: "Amazon.com",
      ShipTo: "9929207",
      ConsigneeAddress:
        "165 GLENN FOX RD, LBE1, NEW STANTON, PA (US), ZIP-15672",
      PurchaseOrder: "67A8K9SO",
      tracking_PROnumber: "2230924175",
      AppointmentNumber: "",
      Door: "DOOR42",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001548",
      TrailerNumber: "513693",
      OrderRcvdDate: "2024-09-09",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000842",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1092171",
      BOLNumber: "12345670000002330",
      BillTo: "Amazon.com",
      ShipTo: "9902244",
      ConsigneeAddress: "1125 W REMINGTON BLVD, ROMEOVILLE, IL (US), ZIP-60446",
      PurchaseOrder: "38VDLKRZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000838",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092187",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916040",
      ConsigneeAddress:
        "676 C FRED JONES BLVD, TPA3, AUBURNDALE, FL (US), ZIP-33823",
      PurchaseOrder: "175Z3OUV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-23",
      orderType: "Standard Order E",
      OrderNumber: "0000000844",
      Priority: "5 - Lowest Priority",
      orderStatus: "Allocated",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-23",
      ScheduledShipDate: "2024-09-23",
      ActualShipDate: null,
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1092192",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "1D121SVR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-07-31",
      orderType: "Standard Order E",
      OrderNumber: "0000000839",
      Priority: "5 - Lowest Priority",
      orderStatus: "Pending",
      TotalOrderLines: 3,
      TotalOrdered: 418.0,
      TotalShipped: 0.0,
      RequestedShipDate: "2024-09-25",
      ScheduledShipDate: "2024-09-25",
      ActualShipDate: null,
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1090945",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "990218X",
      ConsigneeAddress: "10240 OLD DOWD RD, CHARLOTTE, NC (US), ZIP-28214-8082",
      PurchaseOrder: "8MKDFIBG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-23",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000657",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091679",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914234",
      ConsigneeAddress:
        "1200 WESTPORT RD, PIT2, IMPERIAL, PA (US), ZIP-15126-1002",
      PurchaseOrder: "48ZM5YHC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000658",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091694",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8002983",
      ConsigneeAddress: "500 MCCARTHY DR., LEWISBERRY, PA (US), ZIP-17339",
      PurchaseOrder: "68AO5KUG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000661",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 132.0,
      TotalShipped: 132.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-13",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1091672",
      BOLNumber: "12345670000002112",
      BillTo: "Amazon.com",
      ShipTo: "991837X",
      ConsigneeAddress:
        "27505 SW 132ND AVE, HOMESTEAD, HOMESTEAD, FL (US), ZIP-33032",
      PurchaseOrder: "3X2T1TRK",
      tracking_PROnumber: "838738854",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001563",
      TrailerNumber: "930302",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000662",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091675",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609841",
      ConsigneeAddress:
        "255 PARK CENTER DRIVE, PATTERSON, CA (US), ZIP-953638876",
      PurchaseOrder: "41LKQJ2U",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000686",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091665",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914714",
      ConsigneeAddress:
        "7148 W. OLD BINGHAM HWY, SLC2, WEST JORDAN, UT (US), ZIP-84081",
      PurchaseOrder: "2QPUMVCU",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000687",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091671",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9920455",
      ConsigneeAddress:
        "3550 NE EVANGELINE TRWY, LFT1, CARENCRO, LA (US), ZIP-70520-5976",
      PurchaseOrder: "3JSPOABG",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000691",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091667",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916040",
      ConsigneeAddress:
        "676 C FRED JONES BLVD, TPA3, AUBURNDALE, FL (US), ZIP-33823",
      PurchaseOrder: "2YFO748F",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000692",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091680",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991627X",
      ConsigneeAddress:
        "23714 W AMOCO RD, ORD2, CHANNAHON, IL (US), ZIP-60410-8758",
      PurchaseOrder: "4BGX1N9X",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000693",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091691",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991417X",
      ConsigneeAddress:
        "2600 N NORMANDY BLVD, MCO2, DELTONA, FL (US), ZIP-32725-2610",
      PurchaseOrder: "4XO29YJV",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000696",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091668",
      BOLNumber: "12345670000002183",
      BillTo: "Amazon.com",
      ShipTo: "7608918",
      ConsigneeAddress: "800 N 75TH AVE, PHOENIX, AZ (US), ZIP-85043-2101",
      PurchaseOrder: "2ZIG2JVO",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 5,
      LoadNumber: "0000001547",
      TrailerNumber: "V503546",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000697",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "ONTRAC",
      CarrierName: "EMS & ONTRAC",
      ExternalOrderNo: "1091705",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905073",
      ConsigneeAddress: "22205 EAST 19TH AVE, AURORA, CO (US), ZIP-80019",
      PurchaseOrder: "8LE2ZWML",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000701",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091669",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9905960",
      ConsigneeAddress:
        "165 BUSINESS BLVD, BWI4, CLEAR BROOK, VA (US), ZIP-22624-1568",
      PurchaseOrder: "3G47H2LC",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000702",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 6.0,
      TotalShipped: 6.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091673",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918604",
      ConsigneeAddress:
        "23257 S. CENTRAL AVENUE, IGQ2, UNIVERSITY PARK, IL (US), ZIP-60484-3271",
      PurchaseOrder: "3YWKMXMZ",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000703",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091686",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8018154",
      ConsigneeAddress: "675 ALLEN ROAD, CARLISLE, PA (US), ZIP-17015",
      PurchaseOrder: "4R1ZL7BT",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000704",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091698",
      BOLNumber: "12345670000002079",
      BillTo: "Amazon.com",
      ShipTo: "9905901",
      ConsigneeAddress: "20901 KAMERIA AVE, RIVERSIDE, CA (US), ZIP-92518-1513",
      PurchaseOrder: "6O6ITWDF",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 1,
      LoadNumber: "0000001547",
      TrailerNumber: "V503546",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000705",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091707",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9908196",
      ConsigneeAddress:
        "6500 DAVIDSON HWY, CLT3, CONCORD, NC (US), ZIP-28027-7995",
      PurchaseOrder: "8UXN69DL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000706",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091676",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8021686",
      ConsigneeAddress: "5000 COMMERCE WAY, PETERSBURG, VA (US), ZIP-23803",
      PurchaseOrder: "448C3B4P",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000711",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091684",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801955",
      ConsigneeAddress: "19645 WAVERLY RD, EDGERTON, KS (US), ZIP-66021",
      PurchaseOrder: "4NN9IR2S",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000716",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 8.0,
      TotalShipped: 8.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091693",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9914099",
      ConsigneeAddress:
        "1696 HILLCREST AVE, ALB1, S SCHODACK, NY (US), ZIP-12033-9634",
      PurchaseOrder: "66FWJGZR",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000717",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091701",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "991630X",
      ConsigneeAddress:
        "1494 S WATERMAN AVE, SBD2, SAN BERNARDINO, CA (US), ZIP-92408-2805",
      PurchaseOrder: "829WP95L",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000721",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091700",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9902244",
      ConsigneeAddress: "1125 W REMINGTON BLVD, ROMEOVILLE, IL (US), ZIP-60446",
      PurchaseOrder: "6YI3PWBL",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-02",
      orderType: "Standard Order E",
      OrderNumber: "0000000722",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 120.0,
      TotalShipped: 120.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-13",
      CarrierCode: "TFIN",
      CarrierName: "T-FORCE FREIGHT(FORMERLY UPGF)",
      ExternalOrderNo: "1091708",
      BOLNumber: "12345670000002154",
      BillTo: "Amazon.com",
      ShipTo: "9933255",
      ConsigneeAddress:
        "6309 BRAGG BLVD, RDU4, FAYETTEVILLE, NC (US), ZIP-28303",
      PurchaseOrder: "3I79D2VW",
      tracking_PROnumber: "838738843",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001563",
      TrailerNumber: "930302",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000726",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091699",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8020345",
      ConsigneeAddress: "225 INFINITY DR NW, CHARLESTON, TN (US), ZIP-37310",
      PurchaseOrder: "6TPSN8XY",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000727",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091706",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9916202",
      ConsigneeAddress:
        "1255 GATEWAY BLVD, JVL1, BELOIT, WI (US), ZIP-53511-9701",
      PurchaseOrder: "8OBLHH5J",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000664",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 12.0,
      TotalShipped: 12.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-09",
      CarrierCode: "AMAZON",
      CarrierName: "AMAZON CARRIER",
      ExternalOrderNo: "1091688",
      BOLNumber: "12345670000002125",
      BillTo: "Amazon.com",
      ShipTo: "7801912",
      ConsigneeAddress: "1610 VAN BUREN ROAD, EASTON, PA (US), ZIP-18045",
      PurchaseOrder: "4T7JC3ML",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "DOOR47",
      Route: "1",
      Stop: 2,
      LoadNumber: "0000001547",
      TrailerNumber: "V503546",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000665",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091697",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "8014531",
      ConsigneeAddress: "715 AIRTECH PARKWAY, PLAINFIELD, IN (US), ZIP-46168",
      PurchaseOrder: "6NEJ496Z",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000666",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 4.0,
      TotalShipped: 4.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091682",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609787",
      ConsigneeAddress: "6000 ENTERPRISE AVENUE, SCHERTZ, TX (US), ZIP-78154",
      PurchaseOrder: "4G984BMK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000667",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091663",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7801971",
      ConsigneeAddress:
        "3050 GATEWAY COMMERCE CTR DR S, EDWARDSVILLE, IL (US), ZIP-620252815",
      PurchaseOrder: "2I92CZ9D",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000668",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 1,
      TotalOrdered: 2.0,
      TotalShipped: 2.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091666",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "7609809",
      ConsigneeAddress: "940 W BETHEL ROAD, COPPELL, TX (US), ZIP-75019",
      PurchaseOrder: "2UFDU42Y",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
    {
      Owner: "DSTVAL",
      Warehouse: "wmwhse1",
      OrderDate: "2024-09-01",
      orderType: "Standard Order E",
      OrderNumber: "0000000669",
      Priority: "5 - Lowest Priority",
      orderStatus: "Shipped",
      TotalOrderLines: 2,
      TotalOrdered: 14.0,
      TotalShipped: 14.0,
      RequestedShipDate: "2024-09-02",
      ScheduledShipDate: "2024-09-02",
      ActualShipDate: "2024-09-10",
      CarrierCode: "UPSGND",
      CarrierName: "UPS ground",
      ExternalOrderNo: "1091678",
      BOLNumber: "",
      BillTo: "Amazon.com",
      ShipTo: "9918523",
      ConsigneeAddress:
        "4930 ALLBAUGH DR, SMF6, SACRAMENTO, CA (US), ZIP-95837-9109",
      PurchaseOrder: "46OMXYVK",
      tracking_PROnumber: "",
      AppointmentNumber: "",
      Door: "",
      Route: " ",
      Stop: null,
      LoadNumber: "",
      TrailerNumber: " ",
      OrderRcvdDate: "2024-09-03",
    },
  ],
};

export const ASN_DATE_WISE_DATA = [
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000018",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-16",
      "totExpectedQty": 500,
      "totReceivedQty": 500,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000018",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000012",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-15",
      "totExpectedQty": 60,
      "totReceivedQty": 60,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000012",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000017",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-01-15",
      "totExpectedQty": 240,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000017",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000016",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-01-15",
      "totExpectedQty": 180,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000016",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "TEST0001",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-15",
      "totExpectedQty": 0,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000019",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-16",
      "totExpectedQty": 378,
      "totReceivedQty": 378,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000019",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000020",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-16",
      "totExpectedQty": 63,
      "totReceivedQty": 63,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000020",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000008",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-17",
      "totExpectedQty": 1,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000008",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000010",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-17",
      "totExpectedQty": 1,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000010",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-17"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000007",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-18",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000007",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000026",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-19",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000026",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-19"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000023",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-18",
      "totExpectedQty": 1,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000023",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000022",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-18",
      "totExpectedQty": 2,
      "totReceivedQty": 2,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000022",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000021",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-18",
      "totExpectedQty": 2,
      "totReceivedQty": 2,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000021",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000015",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-18",
      "totExpectedQty": 1,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000015",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000041",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-22",
      "totExpectedQty": 0,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000031",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-22",
      "totExpectedQty": 4,
      "totReceivedQty": 4,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000031",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000032",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 20,
      "totReceivedQty": 20,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000032",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000040",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000040",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000054",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 4,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-23",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000054",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000066",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 200,
      "totReceivedQty": 200,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000066",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000071",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 20,
      "totReceivedQty": 20,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000071",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000079",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 20,
      "totReceivedQty": 20,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000079",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000080",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 40,
      "totReceivedQty": 40,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000080",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000069",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-25",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000069",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000096",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-26",
      "totExpectedQty": 4,
      "totReceivedQty": 4,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-01-26",
      "ClosedDate": null,
      "WarehouseReference": "0000000048",
      "ExternalReceiptNo": "WMS0000000048",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000092",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-01-26",
      "totExpectedQty": 2,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-01-26",
      "ClosedDate": null,
      "WarehouseReference": "0000000054",
      "ExternalReceiptNo": "WMS0000000054",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000013",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-16",
      "totExpectedQty": 252,
      "totReceivedQty": 252,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000013",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000090",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-26",
      "totExpectedQty": 10,
      "totReceivedQty": 15,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000090",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000011",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-11",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000011",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000068",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 318,
      "totReceivedQty": 318,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000068",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000067",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000067",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000087",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 10,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000087",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000088",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000088",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000089",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000089",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000072",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 30,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000072",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000073",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 42,
      "totReceivedQty": 42,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000073",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000074",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000074",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000075",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000075",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000076",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000076",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000034",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 9,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000034",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000035",
      "ReceiptType": "Purchase Order",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 11,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-01-23",
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000035",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000036",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-22",
      "totExpectedQty": 5,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000036",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000046",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-22",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000046",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000049",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000049",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000037",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000037",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000038",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-22",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000038",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000048",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000048",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000050",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 5,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000050",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000051",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 10,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000051",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000052",
      "ReceiptType": "Purchase Order",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 5,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-01-23",
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000052",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000053",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 7,
      "totReceivedQty": 7,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000053",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000056",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 3,
      "totReceivedQty": 3,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000056",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000077",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 14,
      "totReceivedQty": 14,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000077",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000078",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-24",
      "totExpectedQty": 30,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000078",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000057",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 10,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000057",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000061",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-23",
      "totExpectedQty": 0,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000061",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000082",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-25",
      "totExpectedQty": 0,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000082",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000083",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-26",
      "totExpectedQty": 30,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000083",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000084",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-01-26",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000084",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000103",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 63,
      "totReceivedQty": 63,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000103",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000085",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 30,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000085",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000106",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 7,
      "totReceivedQty": 7,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000106",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000111",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 30,
      "totReceivedQty": 125,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000111",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000104",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 30,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000104",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000105",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 71,
      "totReceivedQty": 71,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000105",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000070",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000070",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000107",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 63,
      "totReceivedQty": 63,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000107",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000108",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-29",
      "totExpectedQty": 252,
      "totReceivedQty": 252,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000108",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000116",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 0,
      "totReceivedQty": 50,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000116",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000117",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 0,
      "totReceivedQty": 62,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000117",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000112",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 40,
      "totReceivedQty": 40,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000112",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000121",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 66,
      "totReceivedQty": 66,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000121",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000113",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 7,
      "totReceivedQty": 7,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000113",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000115",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 8,
      "totReceivedQty": 8,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000115",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000114",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000114",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000126",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000126",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "000000127",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS000000127",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000122",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000122",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000132",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 8,
      "totReceivedQty": 8,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000132",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000134",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000134",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000133",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 462,
      "totReceivedQty": 462,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000133",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000128",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000128",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000123",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-30",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000123",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000138",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 0,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000138",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000137",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 0,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000137",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000136",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 0,
      "totReceivedQty": 59,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000136",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000129",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 290,
      "totReceivedQty": 290,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000129",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000135",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 5,
      "totReceivedQty": 5,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000135",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000141",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 170,
      "totReceivedQty": 170,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000141",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000124",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 130,
      "totReceivedQty": 130,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000124",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000142",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 11,
      "totReceivedQty": 11,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000142",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000125",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 50,
      "totReceivedQty": 50,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000125",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000153",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000153",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000144",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000144",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000152",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 240,
      "totReceivedQty": 240,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000152",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000143",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000143",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000154",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 24,
      "totReceivedQty": 49,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-01-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000154",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000145",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 250,
      "totReceivedQty": 250,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000145",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000155",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-01",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000155",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000147",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-01",
      "totExpectedQty": 11,
      "totReceivedQty": 11,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000147",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000151",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-01-31",
      "totExpectedQty": 60,
      "totReceivedQty": 60,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000151",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-01-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000162",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-01",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000162",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000161",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-01",
      "totExpectedQty": 924,
      "totReceivedQty": 924,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000161",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000166",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-02",
      "totExpectedQty": 600,
      "totReceivedQty": 600,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000166",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000171",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-02",
      "totExpectedQty": 0,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000171",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000172",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-02",
      "totExpectedQty": 0,
      "totReceivedQty": 16,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000172",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000176",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-02",
      "totExpectedQty": 8,
      "totReceivedQty": 8,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-02-02",
      "ClosedDate": null,
      "WarehouseReference": "0000000103",
      "ExternalReceiptNo": "WMS0000000103",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000164",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-06",
      "totExpectedQty": 17,
      "totReceivedQty": 17,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000164",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000167",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-06",
      "totExpectedQty": 2,
      "totReceivedQty": 2,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000167",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000163",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-06",
      "totExpectedQty": 60,
      "totReceivedQty": 60,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-06",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000163",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000168",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-07",
      "totExpectedQty": 65,
      "totReceivedQty": 65,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000168",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000165",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-07",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000165",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000183",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-02-08",
      "totExpectedQty": 35,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000183",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000182",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-08",
      "totExpectedQty": 384,
      "totReceivedQty": 408,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000182",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000170",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-08",
      "totExpectedQty": 30,
      "totReceivedQty": 30,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000170",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000177",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-08",
      "totExpectedQty": 2,
      "totReceivedQty": 2,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-02-08",
      "ClosedDate": null,
      "WarehouseReference": "0000000124",
      "ExternalReceiptNo": "WMS0000000124",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000186",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-08",
      "totExpectedQty": 35,
      "totReceivedQty": 35,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000186",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000188",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-09",
      "totExpectedQty": 10,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-09",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000188",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-09"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000184",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-12",
      "totExpectedQty": 3,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000184",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000206",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-19",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-19",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000206",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-19"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000211",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-20",
      "totExpectedQty": 156,
      "totReceivedQty": 156,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-20",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000211",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000212",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-20",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-20",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000212",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000207",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-20",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-20",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000207",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000218",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-20",
      "totExpectedQty": 40,
      "totReceivedQty": 40,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000218",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000214",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-20",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000214",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000215",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-21",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000215",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-21"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000221",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-21",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000221",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-21"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000219",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-22",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000219",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000222",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-22",
      "totExpectedQty": 60,
      "totReceivedQty": 60,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000222",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000224",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-23",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000224",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000226",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-26",
      "totExpectedQty": 1,
      "totReceivedQty": 1,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000226",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000227",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-26",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000227",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000228",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-26",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000228",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000220",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-02-27",
      "totExpectedQty": 12,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000220",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000236",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-02-27",
      "totExpectedQty": 48,
      "totReceivedQty": 48,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000236",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000241",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-27",
      "totExpectedQty": 2,
      "totReceivedQty": 2,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-02-27",
      "ClosedDate": null,
      "WarehouseReference": "0000000176",
      "ExternalReceiptNo": "WMS0000000176",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000210",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-22",
      "totExpectedQty": 2160,
      "totReceivedQty": 2160,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000210",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000232",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-28",
      "totExpectedQty": 10,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000232",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000242",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-02-28",
      "totExpectedQty": 2,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-02-28",
      "ClosedDate": null,
      "WarehouseReference": "0000000173",
      "ExternalReceiptNo": "WMS0000000173",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000237",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-02-28",
      "totExpectedQty": 6,
      "totReceivedQty": 6,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000237",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000246",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-02-28",
      "totExpectedQty": 6,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-02-28",
      "ClosedDate": null,
      "WarehouseReference": "0000000164",
      "ExternalReceiptNo": "WMS0000000164",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000238",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-28",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000238",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000271",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000271",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000276",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000276",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000239",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 100,
      "totReceivedQty": 100,
      "totDamagedQty": 20,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000239",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000281",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 90,
      "totReceivedQty": 90,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000281",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000277",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 132,
      "totReceivedQty": 132,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000277",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000278",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000278",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000279",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000279",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000280",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000280",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000274",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000274",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000273",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000273",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000272",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000272",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000283",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000283",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000285",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 48,
      "totReceivedQty": 48,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000285",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000275",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 36,
      "totReceivedQty": 18,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000275",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000286",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 10,
      "totReceivedQty": 14,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000286",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000284",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-02-29",
      "totExpectedQty": 36,
      "totReceivedQty": 36,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-02-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000284",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000287",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-01",
      "totExpectedQty": 32,
      "totReceivedQty": 32,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000287",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000267",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-01",
      "totExpectedQty": 4,
      "totReceivedQty": 4,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-01",
      "ClosedDate": null,
      "WarehouseReference": "0000000189",
      "ExternalReceiptNo": "WMS0000000189",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000268",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-01",
      "totExpectedQty": 4,
      "totReceivedQty": 4,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-01",
      "ClosedDate": null,
      "WarehouseReference": "0000000194",
      "ExternalReceiptNo": "WMS0000000194",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000291",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-01",
      "totExpectedQty": 24,
      "totReceivedQty": 24,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000291",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000306",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 4,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000204",
      "ExternalReceiptNo": "WMS0000000204",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000262",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 6,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000203",
      "ExternalReceiptNo": "WMS0000000203",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000301",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 0,
      "totReceivedQty": 10,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000301",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000269",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 12,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000207",
      "ExternalReceiptNo": "WMS0000000207",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000270",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 2,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000199",
      "ExternalReceiptNo": "WMS0000000199",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000302",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 0,
      "totReceivedQty": 20,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000302",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000263",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-05",
      "totExpectedQty": 16,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000209",
      "ExternalReceiptNo": "WMS0000000209",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000311",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-06",
      "totExpectedQty": 0,
      "totReceivedQty": 1200,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000311",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000290",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-06",
      "totExpectedQty": 108,
      "totReceivedQty": 108,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-06",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000290",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000316",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-07",
      "totExpectedQty": 422,
      "totReceivedQty": 422,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000316",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000318",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-07",
      "totExpectedQty": 74,
      "totReceivedQty": 74,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000318",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000321",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-07",
      "totExpectedQty": 6000,
      "totReceivedQty": 6000,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000321",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000298",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-07",
      "totExpectedQty": 144,
      "totReceivedQty": 144,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000298",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000297",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-07",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000297",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000299",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 108,
      "totReceivedQty": 108,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000299",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000322",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 1750,
      "totReceivedQty": 1750,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000322",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000320",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 1300,
      "totReceivedQty": 1300,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000320",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000319",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 1000,
      "totReceivedQty": 800,
      "totDamagedQty": 200,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000319",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000300",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 15000,
      "totReceivedQty": 15000,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000300",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000326",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 4,
      "totReceivedQty": 4,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000326",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000324",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 750,
      "totReceivedQty": 800,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000324",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000323",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 13608,
      "totReceivedQty": 13608,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000323",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000331",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 84,
      "totReceivedQty": 84,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000331",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000332",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-08",
      "totExpectedQty": 96,
      "totReceivedQty": 96,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000332",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000328",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-11",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000328",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000334",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 84,
      "totReceivedQty": 84,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000334",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000329",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-11",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000329",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000327",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 500,
      "totReceivedQty": 500,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000327",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000336",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-11",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000336",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000337",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-11",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-11",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000337",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000330",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 150,
      "totReceivedQty": 150,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000330",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000338",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 156,
      "totReceivedQty": 156,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000338",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000341",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000341",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000346",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 72,
      "totReceivedQty": 72,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000346",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000339",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 160,
      "totReceivedQty": 160,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000339",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000351",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000351",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000340",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-12",
      "totExpectedQty": 360,
      "totReceivedQty": 360,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000340",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000352",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000352",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000361",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 60,
      "totReceivedQty": 60,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000361",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000355",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 164,
      "totReceivedQty": 164,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000355",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000362",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 12,
      "totReceivedQty": 12,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000362",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000358",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 750,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000358",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000363",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-13",
      "totExpectedQty": 120,
      "totReceivedQty": 120,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000363",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000359",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000359",
      "CarrierReference": "LD02992",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000360",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 18,
      "totReceivedQty": 18,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000360",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000371",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000371",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000367",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000367",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000349",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "WH23",
      "ExternalReceiptNo": "WMS0000000349",
      "CarrierReference": "Carrier12",
      "TransportationStatus": "5COMP",
      "TrailerNumber": "trl45",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000365",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000365",
      "CarrierReference": "LD@112",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000369",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-15",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000369",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000370",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-03-15",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000370",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000376",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-15",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000376",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-15"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000040",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000040",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000372",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000372",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000368",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000368",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000387",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000387",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000381",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000381",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000386",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000386",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000388",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000388",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000391",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000391",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000393",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000393",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000382",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000382",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000394",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-20",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000394",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000389",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-20",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000389",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-20"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000395",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-25",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000395",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000390",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000390",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000401",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000401",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000041",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-20",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-26",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000041",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000381",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000381",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000408",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000408",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000418",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000418",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000416",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000416",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000417",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000417",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000410",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000410",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000383",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000383",
      "CarrierReference": "CART0026",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000414",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000414",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000413",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000413",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000423",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000423",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000422",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000422",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000419",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-27",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000419",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000436",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-27",
      "ClosedDate": null,
      "WarehouseReference": "0000000399",
      "ExternalReceiptNo": "WMS0000000399",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000425",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000425",
      "CarrierReference": "12345123445",
      "TransportationStatus": "",
      "TrailerNumber": "TRL12",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000447",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000447",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000441",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000441",
      "CarrierReference": "DWY23224",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000442",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000442",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000424",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000424",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000432",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-28",
      "ClosedDate": null,
      "WarehouseReference": "0000000393",
      "ExternalReceiptNo": "WMS0000000393",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000448",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-03-28",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-03-28",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000448",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000451",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000451",
      "CarrierReference": "CARR00982",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000449",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000449",
      "CarrierReference": "DYR22012",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000452",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-02",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000452",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD000420",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-02",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "IGD000420",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-02"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000444",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-03",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000444",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-03"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000443",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-03",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-31",
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000443",
      "CarrierReference": "CARR00223",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000380",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-03-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000380",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000453",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-03",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000453",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-03"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000427",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-04-04",
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "0000000395",
      "ExternalReceiptNo": "WMS0000000395",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000454",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000454",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000455",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000455",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000457",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000457",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000458",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000458",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000459",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000459",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000461",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000461",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000462",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000462",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000466",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000466",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000460",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-08",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000460",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000450",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-03",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000450",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-03"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000471",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-10",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000471",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000463",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-10",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000463",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000467",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000467",
      "CarrierReference": "Carrier01",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000464",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-10",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000464",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000481",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-12",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000481",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000465",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-12",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000465",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000473",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-12",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000473",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000478",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000478",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000475",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-16",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-16",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000475",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000428",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-16",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-04-16",
      "ClosedDate": null,
      "WarehouseReference": "0000000400",
      "ExternalReceiptNo": "WMS0000000400",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000482",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-16",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-16",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000482",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-16"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000400",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-17",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000400",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000479",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-19",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-19",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000479",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-19"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000497",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000497",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000498",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000498",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD0000448",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIGD0000448",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000499",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000499",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000501",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "0000000498",
      "ExternalReceiptNo": "WMS0000000498",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000493",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-23",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-04-23",
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000493",
      "CarrierReference": "CARRE54",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SH041824",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-24",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-24",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "SH041824",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SH040824",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-09",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-09",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "SH040824",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-09"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SH042424",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-24",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-24",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "SH042424",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SH04242402",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-24",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-24",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "SH04242402",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IGD000456",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-24",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "IGD000456",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-24"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000513",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-25",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000513",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000521",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000521",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000516",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000516",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000510",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000510",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000514",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-25",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-04-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000514",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-25"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000515",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000515",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000517",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000517",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000522",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000522",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000523",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000523",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000518",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000518",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000524",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000524",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000525",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000525",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IND0000534",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIND0000534",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000541",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000541",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000535",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000535",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000530",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000530",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000536",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000536",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000546",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000546",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000537",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000537",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000543",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000543",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000547",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000547",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000538",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-04-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000538",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000533",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-04-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000533",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000527",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-04-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000527",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000181",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-05-01",
      "totExpectedQty": 10,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000181",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-02-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000420",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-05-01",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000420",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000548",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-04-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-02",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000548",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000532",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-04-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-02",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000532",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IND0000533",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-05-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIND0000533",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-04-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000415",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-05-02",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000415",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-28"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000347",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-05-02",
      "totExpectedQty": 264,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-03-11",
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000347",
      "CarrierReference": "mk125",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-03-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IND0000561",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-05-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIND0000561",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000556",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-05-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000556",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000561",
      "ReceiptType": "Normal",
      "ReceiptStatus": "In Receiving",
      "ReceiptDate": "2024-05-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000561",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000566",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-06",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000566",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "IND0000556",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-21",
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSIND0000556",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000571",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-05-21",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000571",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-21"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000577",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000577",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000582",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000582",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000573",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000573",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000578",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000578",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000579",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-22",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000579",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000575",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-05-27",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000575",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-27"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000586",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-05-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000586",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000583",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000583",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000591",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000591",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000592",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-29",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-29",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000592",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-29"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000593",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000593",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000596",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000596",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000597",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000597",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000598",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000598",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000602",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000602",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000600",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000600",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000599",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-30",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000599",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000606",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000606",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000603",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-05-30",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000603",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-30"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000608",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-31",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000608",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000609",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-31",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000609",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000611",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-31",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000611",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000612",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-05-31",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-05-31",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000612",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-05-31"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000610",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-01",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000610",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000605",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-03",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-03",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000605",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-03"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000613",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000613",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000617",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000617",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000508",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000508",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000614",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-04",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000614",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000501",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000501",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000502",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000502",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000503",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000503",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000505",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000505",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "SIT0000504",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-04",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": " ",
      "ExternalReceiptNo": "WMSSIT0000504",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-04"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000626",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-05",
      "ClosedDate": null,
      "WarehouseReference": "0000000709",
      "ExternalReceiptNo": "WMS0000000709",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000622",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000622",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000623",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000623",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000615",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-06",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000615",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000631",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-06",
      "ClosedDate": null,
      "WarehouseReference": "0000000712",
      "ExternalReceiptNo": "WMS0000000712",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000632",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-06",
      "ClosedDate": null,
      "WarehouseReference": "0000000713",
      "ExternalReceiptNo": "WMS0000000713",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000633",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-06-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-06",
      "ClosedDate": null,
      "WarehouseReference": "0000000715",
      "ExternalReceiptNo": "WMS0000000715",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000636",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-06-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-06",
      "ClosedDate": null,
      "WarehouseReference": "0000000714",
      "ExternalReceiptNo": "WMS0000000714",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000620",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000620",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000652",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-10",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000652",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000658",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-11",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-11",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000658",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-11"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000649",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-12",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000649",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000650",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000650",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000653",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000653",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000654",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000654",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000659",
      "ReceiptType": "Customer Return",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000659",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000660",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000660",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000662",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000662",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000661",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000661",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000638",
      "ReceiptType": "Kit Order ASN",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": "2024-06-14",
      "ClosedDate": "2024-06-14",
      "WarehouseReference": "0000000744",
      "ExternalReceiptNo": "WMS0000000744",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000671",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000671",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000672",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000672",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000677",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000677",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000686",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-18",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000686",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000681",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-06-18",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-06-18",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000681",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-18"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000691",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-06-21",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000691",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-06-21"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000701",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-07-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000701",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000706",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-07-10",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000706",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-10"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000715",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-07-22",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-07-23",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000715",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-22"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000700",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Closed",
      "ReceiptDate": "2024-07-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-07-26",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000700",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000726",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-07-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000726",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000721",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-07-26",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000721",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-07-26"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000736",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-01",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000736",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000732",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-01",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-01",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000732",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-01"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000742",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000742",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000751",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000751",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000743",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000743",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000752",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000752",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000753",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000753",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000735",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000735",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000756",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000756",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000738",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000738",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000757",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000757",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000754",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000754",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000755",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000755",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000759",
      "ReceiptType": "Normal",
      "ReceiptStatus": "New",
      "ReceiptDate": "2024-08-06",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000759",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-06"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000761",
      "ReceiptType": "Standard ASN E",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000761",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000763",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000763",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000762",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000762",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000739",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000739",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000740",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000740",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000760",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000760",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000764",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-07",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-07",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000764",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-07"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000779",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-08",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000779",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000787",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-08",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000787",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000780",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-08",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000780",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000784",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-08",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-08",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000784",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-08"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000807",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-09",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-09",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000807",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-09"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000805",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-09",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-09",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000805",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-09"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000800",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-09",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-09",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000800",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-09"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000809",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-12",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-12",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000809",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-12"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000826",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-13",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-13",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000826",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-13"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000818",
      "ReceiptType": "Standard ASN M",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-14",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-14",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000818",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-14"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000758",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-08-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-08-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000758",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000831",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Received",
      "ReceiptDate": "2024-08-23",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": null,
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000831",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-08-23"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000851",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-09-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-09-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000851",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-09-05"
    },
    {
      "Owner": "DSTVAL",
      "Warehouse": "wmwhse1",
      "ASN_ReceiptNo": "0000000842",
      "ReceiptType": "Normal",
      "ReceiptStatus": "Verified Closed",
      "ReceiptDate": "2024-09-05",
      "totExpectedQty": 0,
      "totReceivedQty": 0,
      "totDamagedQty": 0,
      "ExpectedReceiptDate": null,
      "ClosedDate": "2024-09-05",
      "WarehouseReference": "",
      "ExternalReceiptNo": "WMS0000000842",
      "CarrierReference": "",
      "TransportationStatus": "",
      "TrailerNumber": "",
      "DateCreated": "2024-09-05"
    }
  ]

export const ORDER_COUNT = [
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-08T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-04T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-16T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-17T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-23T00:00:00",
    totalOrders: 5,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-24T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-29T00:00:00",
    totalOrders: 3,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-30T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-01T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-02T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-07T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-08T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-13T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-15T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-11T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-22T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-28T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-15T00:00:00",
    totalOrders: 4,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-07T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-23T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-11T00:00:00",
    totalOrders: 3,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-14T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-25T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-27T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-29T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-06T00:00:00",
    totalOrders: 3,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-16T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-17T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-29T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-07T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-04T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-05T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-04T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 3,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-29T00:00:00",
    totalOrders: 7,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-24T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-30T00:00:00",
    totalOrders: 3,
    totalShipped: 0,
    totalPending: 3,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-02T00:00:00",
    totalOrders: 4,
    totalShipped: 0,
    totalPending: 4,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-08T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-13T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-28T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-11T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 2,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-14T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-18T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-20T00:00:00",
    totalOrders: 4,
    totalShipped: 0,
    totalPending: 4,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-28T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-03T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-12T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 2,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-16T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-17T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-22T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-29T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-30T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 2,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-05-03T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 1,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-23T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-07T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-08T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-28T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-04T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-05T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-11T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-14T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-19T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-26T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-01T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-17T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-18T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 1,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-05-03T00:00:00",
    totalOrders: 2,
    totalShipped: 0,
    totalPending: 0,
    totalItemsShipped: 0,
    totalItemQtyShipped: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-22T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 63.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-23T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 1.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-24T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 76.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-25T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 130.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-26T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 2.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-30T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 30.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-01-31T00:00:00",
    totalOrders: 6,
    totalShipped: 6,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 506.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-01T00:00:00",
    totalOrders: 6,
    totalShipped: 6,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 384.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-02T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 41.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-06T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 3.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-07T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 21.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-08T00:00:00",
    totalOrders: 11,
    totalShipped: 11,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 356.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-09T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 24.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-13T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 70.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-16T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 6.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-20T00:00:00",
    totalOrders: 6,
    totalShipped: 6,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 700.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-23T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 132.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-27T00:00:00",
    totalOrders: 5,
    totalShipped: 5,
    totalPending: 0,
    totalItemsShipped: 6,
    totalItemQtyShipped: 531.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-28T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 6.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-02-29T00:00:00",
    totalOrders: 4,
    totalShipped: 4,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 82.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-01T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 14.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-05T00:00:00",
    totalOrders: 5,
    totalShipped: 5,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 513.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-06T00:00:00",
    totalOrders: 6,
    totalShipped: 6,
    totalPending: 0,
    totalItemsShipped: 5,
    totalItemQtyShipped: 108.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-07T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 6,
    totalItemQtyShipped: 252.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-08T00:00:00",
    totalOrders: 8,
    totalShipped: 8,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 1930.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-11T00:00:00",
    totalOrders: 4,
    totalShipped: 4,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 862.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-12T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 236.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-13T00:00:00",
    totalOrders: 5,
    totalShipped: 5,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 318.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-14T00:00:00",
    totalOrders: 7,
    totalShipped: 7,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 1506.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-15T00:00:00",
    totalOrders: 8,
    totalShipped: 8,
    totalPending: 0,
    totalItemsShipped: 5,
    totalItemQtyShipped: 3900.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-18T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 360.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-19T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 636.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-20T00:00:00",
    totalOrders: 9,
    totalShipped: 9,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 1332.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-22T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 126.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-26T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 372.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-27T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 360.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-03-28T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 5,
    totalItemQtyShipped: 462.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-04T00:00:00",
    totalOrders: 4,
    totalShipped: 4,
    totalPending: 0,
    totalItemsShipped: 3,
    totalItemQtyShipped: 624.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-08T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 72.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-10T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 360.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-12T00:00:00",
    totalOrders: 4,
    totalShipped: 4,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 1500.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-16T00:00:00",
    totalOrders: 3,
    totalShipped: 3,
    totalPending: 0,
    totalItemsShipped: 1,
    totalItemQtyShipped: 1080.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-17T00:00:00",
    totalOrders: 5,
    totalShipped: 5,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 1390.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-19T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 390.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-22T00:00:00",
    totalOrders: 2,
    totalShipped: 2,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 540.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-24T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 2,
    totalItemQtyShipped: 36.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    OrderDate: "2024-04-29T00:00:00",
    totalOrders: 1,
    totalShipped: 1,
    totalPending: 0,
    totalItemsShipped: 4,
    totalItemQtyShipped: 420.0,
  },
];

export const ASN_COUNT = [
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-11T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-15T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 10.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-16T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 223.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-17T00:00:00",
    totalASN: 2,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 1.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-18T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 5,
    totalPiecesRcvd: 123.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-19T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 12.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-22T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-23T00:00:00",
    totalASN: 14,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 10.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-24T00:00:00",
    totalASN: 9,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 120.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-25T00:00:00",
    totalASN: 8,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 60.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-26T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 19.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-29T00:00:00",
    totalASN: 9,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 3,
    totalPiecesRcvd: 141.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-30T00:00:00",
    totalASN: 13,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 5,
    totalPiecesRcvd: 300.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-01-31T00:00:00",
    totalASN: 20,
    totalVerifiedClosedASN: 12,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 694.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-01T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 6.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-02T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 6,
    totalPiecesRcvd: 144.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-06T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 17.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-08T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 4,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 362.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-09T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-12T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-19T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-20T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 160.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-21T00:00:00",
    totalASN: 2,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 192.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-22T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 180.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-23T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 12.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-26T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-27T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 2.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-28T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 6.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-02-29T00:00:00",
    totalASN: 16,
    totalVerifiedClosedASN: 16,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-01T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 36.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-05T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 30.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-06T00:00:00",
    totalASN: 2,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 1200.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-07T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 5,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-08T00:00:00",
    totalASN: 10,
    totalVerifiedClosedASN: 9,
    totalItemsRcvd: 2,
    totalPiecesRcvd: 108.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-11T00:00:00",
    totalASN: 6,
    totalVerifiedClosedASN: 4,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 584.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-12T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 6,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 360.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-13T00:00:00",
    totalASN: 6,
    totalVerifiedClosedASN: 4,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 12.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-14T00:00:00",
    totalASN: 6,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 18.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-15T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-18T00:00:00",
    totalASN: 8,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-20T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-25T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-26T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-27T00:00:00",
    totalASN: 11,
    totalVerifiedClosedASN: 7,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-03-28T00:00:00",
    totalASN: 7,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-02T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-03T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-04T00:00:00",
    totalASN: 11,
    totalVerifiedClosedASN: 10,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-08T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-10T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 4,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-12T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-15T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-16T00:00:00",
    totalASN: 3,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-17T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-19T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-22T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 2,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-23T00:00:00",
    totalASN: 1,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-24T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 3,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-25T00:00:00",
    totalASN: 2,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-26T00:00:00",
    totalASN: 10,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-29T00:00:00",
    totalASN: 10,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-04-30T00:00:00",
    totalASN: 4,
    totalVerifiedClosedASN: 1,
    totalItemsRcvd: 0,
    totalPiecesRcvd: 0.0,
  },
  {
    custCode: "DSTVAL",
    WHSEID: "wmwhse1",
    ReceiptDate: "2024-05-02T00:00:00",
    totalASN: 5,
    totalVerifiedClosedASN: 0,
    totalItemsRcvd: 1,
    totalPiecesRcvd: 0.0,
  },
];
