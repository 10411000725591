import React from 'react'
import PageHeader from '../../components/PageHeader'
import ShipmentReportDateWiseTable from './InvoiceReportTable'


const InvoiceReport = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Invoice Report"/>
      <ShipmentReportDateWiseTable />
    </div>
  )
}

export default InvoiceReport