import React, { useEffect } from "react";
// import "./createUser.css";
// import { Table } from "react-bootstrap";
import Table from "@mui/material/Table";
import { useNavigate } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import {
  Button,
  Grid,
  Paper,
  Stack,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";
import CardComponent from "components/Card";
import "css/pages/user.css"
import styled from "@emotion/styled";

export default function Users() {

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#f9f9f9",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      cursor:"pointer"
    },
  }));

  const { user } = useSelector((state) => state.user);
  const [userData, setData] = React.useState([]);
  const [createuserData, setcreateuserData] = React.useState([]);

  const key = CryptoJS.enc.Utf8.parse("bwplAPVp1OH7Xzyr");
  const iv = CryptoJS.enc.Utf8.parse("bwplAPVp1OH7Xzyr");

  const [fname, setfName] = React.useState("");
  const [lName, setlName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [iD, setID] = React.useState("");

  const [isReset, setIsReset] = React.useState(false);
  const [userMsg, setUserMsg] = React.useState("");

  const [msgCss, setmsgCss] = React.useState("");

  const [pwdClass, setpwdClass] = React.useState(false);
  const [resetButton, setresetButton] = React.useState("hidden");
  const [pwd, setPwd] = React.useState("");
  const [confirmPwd, setconfirmPwd] = React.useState("");

  const apiUrlGetData =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/GetCreateUsersPgData";

  const apiUrlCreateData =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/AddUserDtls";
  const apiUrlUpdateData =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/UpdateUserDtls";

  const apiUrlReset =
    "https://igdstg.igdistribution.net/igdcpapi/api/Admin/ResetUserPswd";

  const navigation = useNavigate();

  useEffect(() => {
    try {
      getuserData();
    } catch (e) {
      //log error
    }
  }, []);

  async function getuserData() {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {};
    const response = await postRequest(
      "Admin/GetCreateUsersPgData",
      payload,
      headers
    );

    if (response) {
      setData(response.data.Payload.Table1);
    }
  }

  async function createUserData(param) {
    const responseUserCreate = await createUser(param);

    if (responseUserCreate) {
      if (responseUserCreate?.status == 400) {
        setUserMsg("something went wrong");
        setmsgCss("loginMsg");
      } else if (responseUserCreate?.status == 401) {
        navigation("/login");
      } else {
        setcreateuserData(responseUserCreate);
        if (responseUserCreate?.Status == 0) {
          //set error msg
          setUserMsg(responseUserCreate.Message);
          setmsgCss("loginMsg");
        } else {
          setUserMsg(responseUserCreate.Message);
          setmsgCss("loginSuccMsg");
          if (responseUserCreate.Message != "") {
            getuserData();
          }
        }
      }
    }
  }

  async function updateUserData(param) {
    const responseUserCreate = await updateUser(param);

    if (responseUserCreate) {
      if (responseUserCreate?.status == 400) {
        setUserMsg("something went wrong");
        setmsgCss("loginMsg");
      } else if (responseUserCreate?.status == 400) {
        navigation("/login");
      } else {
        setcreateuserData(responseUserCreate);
        if (responseUserCreate?.Status == 0) {
          //set error msg
          setUserMsg(responseUserCreate.Message);
          setmsgCss("loginMsg");
        } else {
          setUserMsg(responseUserCreate.Message);
          setmsgCss("loginSuccMsg");
          getuserData();
        }
      }
    }
  }

  async function resetUser(param) {
    //set reset field
    setIsReset(true);
    setpwdClass(false);
  }
  async function createUserButton(param) {
    //set reset field
    clearData();
    setIsReset(false);
  }

  async function resetUserPwd(param) {
    //set reset field
    if (pwd != "") {
      setIsReset(true);
      const encryptedPwd = CryptoJS.AES.encrypt(pwd, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const params = {
        uid: iD,
        newPassword: encryptedPwd.toString(),
      };
      const responseReset = await resetPwd(params);

      if (responseReset) {
        if (responseReset?.status == 400) {
          setUserMsg("something went wrong");
          setmsgCss("loginMsg");
        } else {
          setcreateuserData(responseReset);
          if (responseReset?.Status == 0) {
            //set error msg
            setUserMsg(responseReset.Message);
            setmsgCss("loginMsg");
          } else {
            setUserMsg(responseReset.Message);
            setmsgCss("loginSuccMsg");
            getuserData();
          }
        }
      }
    } else {
      setUserMsg("Enter password");
    }
  }

  async function createUser(credentials) {
    return fetch(apiUrlCreateData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        setmsgCss("loginMsg");
        setUserMsg("Something went wrong, please try again");
        console.log("Failed");
      });
  }

  async function updateUser(credentials) {
    return fetch(apiUrlUpdateData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        setmsgCss("loginMsg");
        setUserMsg("Something went wrong, please try again");
        console.log("Failed");
      });
  }

  async function resetPwd(credentials) {
    return fetch(apiUrlReset, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        navigation("/login");
      });
  }
  const submitData = (e) => {
    e.preventDefault();
    if (iD) {
      const userDataValues = {
        Uid: iD,
        ClientId: "2",
        FirstName: fname,
        LastName: lName,
        PhoneNumber: phone,
        Email: email,
        Password: "",
        ConfirmPassword: "",
      };

      const updateUserResponse = updateUserData(userDataValues);

      // if (updateUserResponse) {
      //     console.log("createUserResponse", updateUserResponse)
      // }
    } else {
      const encryptedPwd = CryptoJS.AES.encrypt(pwd, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const encryptedConfirmPwd = CryptoJS.AES.encrypt(pwd, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      const userDataValues = {
        ClientId: "2",
        FirstName: fname,
        LastName: lName,
        PhoneNumber: phone,
        Email: email,
        Password: encryptedPwd.toString(),
        ConfirmPassword: encryptedConfirmPwd.toString(),
      };

      const createUserResponse = createUserData(userDataValues);

      // if (createUserResponse) {
      //     console.log("createUserResponse", createUserResponse)
      // }
    }
  };

  const clearData = () => {
    setfName("");
    setlName("");
    setEmail("");
    setPhone("");
    setID("");
    setPwd("");
    setconfirmPwd("");
    setUserMsg("");
    setpwdClass(false);
  };

  const onEditUser = (e) => {
    if (e != undefined) {
      setresetButton("");
      setpwdClass(true);
      setfName(e?.FirstName);
      setlName(e?.LastName);
      setEmail(e.Email);
      setPhone(e.PhoneNumber);
      setID(e.Id);

      //post edit form
    }
  };

  return (
    <section className="userPage">
      <div className="container py-5 h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-lg-9 col-xl-7 mt-8">
            <div className="mb-8  text-center pageHeading">
              <h1>App Settings - User</h1>
            </div>
            <div
              className="card shadow-2-strong card-registration"
              style={{ borderRadius: 15 }}
            >
              {!isReset ? (
                <>
                   <h5>Create/Edit User</h5>
                  <CardComponent className="card-body p-4 p-md-5">
                   
                    <form onSubmit={submitData}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label className="form-label" htmlFor="firstName">
                              First Name
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              autoFocus
                              required
                              value={fname}
                              onChange={(e) => setfName(e.target.value)}
                              className="form-control form-control-lg"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label className="form-label" htmlFor="lastName">
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              value={lName}
                              required
                              onChange={(e) => setlName(e.target.value)}
                              className="form-control form-control-lg"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label
                              className="form-label"
                              htmlFor="emailAddress"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              id="emailAddress"
                              className="form-control form-control-lg"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label className="form-label" htmlFor="phoneNumber">
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              required
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              id="phoneNumber"
                              className="form-control form-control-lg"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                            <input
                              type="password"
                              value={pwd}
                              required
                              disabled={pwdClass}
                              onChange={(e) => setPwd(e.target.value)}
                              id="password"
                              className={"form-control form-control-lg"}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack spacing={1}>
                            <label
                              className="form-label"
                              htmlFor="confirmpassword"
                            >
                              Confirm password
                            </label>
                            <input
                              type="password"
                              value={confirmPwd}
                              disabled={pwdClass}
                              required
                              onChange={(e) => setconfirmPwd(e.target.value)}
                              id="confirmpassword"
                              className="form-control form-control-lg"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1} direction="row">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="medium"
                            >
                              Submit
                            </Button>
                            {/* <input
                              className="btn btn-primary "
                              type="submit"
                              value="Submit"
                            /> */}

                            <Button
                              onClick={clearData}
                              variant="contained"
                              color="primary"
                            >
                              Clear
                            </Button>
                            {pwdClass && (
                              <Button
                                onClick={resetUser}
                                variant="contained"
                                color="primary"
                              >
                                Reset Password
                              </Button>
                              // <input
                              //   className="btn btn-primary ml-2"
                              //   type="button"
                              //   onClick={resetUser}
                              //   value="Reset Password"
                              // />
                            )}
                          </Stack>
                        </Grid>
                      </Grid>

                      <div className="mt-2 pt-2">
                        <p className={msgCss}>{userMsg}</p>
                      </div>
                    </form>
                  </CardComponent>
                </>
              ) : (
                <CardComponent className="card-body p-4 p-md-5">
                  <h5 className="mb-8 font-bold  pb-md-0 mb-md-5">
                    Reset Password
                  </h5>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <label className="form-label" htmlFor="password">
                          Enter New Password
                        </label>
                        <input
                          type="password"
                          value={pwd}
                          required
                          disabled={pwdClass}
                          onChange={(e) => setPwd(e.target.value)}
                          id="password"
                          className={"form-control form-control-lg"}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <Button
                          onClick={resetUserPwd}
                          variant="contained"
                          color="primary"
                        >
                          Reset
                        </Button>
                        {/* <input
                          className="btn btn-primary ml-2"
                          type="button"
                          onClick={resetUserPwd}
                          value="Reset"
                        /> */}
                        <Button
                          onClick={createUserButton}
                          variant="contained"
                          color="primary"
                        >
                          Back
                        </Button>
                        {/* <input
                          className="btn btn-primary ml-2"
                          type="button"
                          onClick={createUserButton}
                          value="Back"
                        /> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={msgCss}>{userMsg}</p>
                    </Grid>
                  </Grid>
                </CardComponent>
              )}
            </div>
          </div>
        </div>
        {!isReset && (
          <div>
            <h5 className="font-bold">User List</h5>
            <TableContainer component={Paper}>
              <Table striped bordered hover variant="dark">
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody> 
                  {userData?.map((s) => {
                    return (
                      <StyledTableRow
                        className="clickable"
                        onClick={() => onEditUser(onEditUser(s))}
                      >
                        <StyledTableCell>{s.UserName}</StyledTableCell>
                        <StyledTableCell>{s.FirstName}</StyledTableCell>
                        <StyledTableCell>{s.LastName}</StyledTableCell>
                        <StyledTableCell>{s.PhoneNumber}</StyledTableCell>
                        <StyledTableCell>{s.Email}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </section>
  );
}
