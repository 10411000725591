import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const columns = [
  {
    field: "ASN_ReceiptNo",
    headerClassName: "headingbg-color",
    headerName: "ASN ReceiptNo",
    width: 150,
  },
  {
    field: "ReceiptType",
    headerClassName: "headingbg-color",
    headerName: "Receipt Type",
    width: 150,
  },
  {
    field: "ReceiptDate",
    headerClassName: "headingbg-color",
    headerName: "Receipt Date",
    width: 200,
  },

  {
    field: "totExpectedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Expected Qty",
    width: 200,
  },
  {
    field: "totReceivedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Received Qty",
    width: 200,
  },
  {
    field: "totDamagedQty",
    headerClassName: "headingbg-color",
    headerName: "Total Damaged Qty",
    width: 200,
  },

  {
    field: "ReceiptStatus",
    headerClassName: "headingbg-color",
    headerName: "Receipt Status",
    width: 300,
  },
  {
    field: "ExpectedArrivalDate",
    headerClassName: "headingbg-color",
    headerName: "Expected Arrival Date",
    width: 200,
  },

  {
    field: "WarehouseReference",
    headerClassName: "headingbg-color",
    headerName: "Warehouse Reference",
    width: 200,
  },
  {
    field: "DateCreated",
    headerClassName: "headingbg-color",
    headerName: "Date Created",
    width: 200,
  },
];

const ASNReceiptTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
      receiptStatus: "all",
      receiptType: "all",
    };
    const response = await postRequest(
      "user/getDashData_ASNReceipts",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c) => ({
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    ASN_ReceiptNo: c.ASN_ReceiptNo,
    ReceiptType: c.ReceiptType,
    ReceiptDate: c.ReceiptDate,
    totExpectedQty: c.totExpectedQty,
    totReceivedQty: c.totReceivedQty,
    totDamagedQty: c.totDamagedQty,
    ReceiptStatus: c.ReceiptStatus,
    ExpectedArrivalDate: c.ExpectedArrivalDate,
    WarehouseReference: c.WarehouseReference,
    DateCreated: c.DateCreated,
  }));
  return (
    <div
      style={{
        height: 600,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowId={() => Math.floor(Math.random() * 100000000)}
        columns={columns}
        rows={rows}
        //   style={{alignItems: 'center'}}
      ></DataGridPremium>
    </div>
  );
};

export default ASNReceiptTable;
