import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    startDate: moment().subtract(29, "days"),
    endDate: moment()
  },
  reducers: {
    setDashboardDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const { setDashboardDate } = dashboardSlice.actions;



export default dashboardSlice.reducer;
